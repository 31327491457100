<template>
  <div class="rx_wrapper" :style="[{width:width},{height:height}]">
    <div class="pulldownBackground" :style="[{opacity:threshold}]" ref="pdm" v-if="isPulldown">
      <span :style="{transform: 'scale('+threshold+')'}">
        <slot name="pulldown">{{renewTxt}}</slot>
      </span>
    </div>
    <div
      class="wrapper"
      ref="wrapper"
      :class="[{transition:transition,scrolling:scrolling,isPulldown:isPulldown,hideBar:hideBar},wrapperClass]"
      @touchstart="__touchstart"
    >
      <slot></slot>
      <div class="nomore" ref="nomore" v-if="nomore">
        <span>已经没有更多内容了哦~</span>
      </div>
    </div>
  </div>
</template>
 
<script>
export default {
  name: "WapView",
  props: {
    items: {
      type: Array,
    },
    width: String,
    height: String,
    pulldown: {
      type: Boolean,
      default: false,
    },
    nomore: {
      type: Boolean,
      default: false,
    },
    pullData: Object,
    hideBar: {
      type: Boolean,
      default: true,
    },
    wrapperClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      renewTxt: "下拉刷新",
      scrolltop: 0, //滚动头部距离
      reachBottom: false, //是否触发了底部
      transition: false,
      scrolling: true,
      isPulldown: false,
      threshold: 0, //下拉触发的临界值，范围0-1
      dragArea: {
        height: 100,
        dist: 50,
      },
      startDrag: false, //是否开始拖动
      startY: 0, //初始位置Y
      dragY: 0, //拖动Y距离
    };
  },
  watch: {
    items(a, b) {
      if (a.length > b.length) {
        let _scrolltop = this.scrolltop;
        setTimeout(() => {
          if (_scrolltop == this.scrolltop) {
            this.scrollYBy(60);
          }
        }, 300);
      }
    },
    nomore(a) {
      if (a === true) {
        setTimeout(() => {
          this.scrollYBy(70);
        }, 300);
      }
    },
    pullData(a) {
      Object.assign(this.dragArea, a);
    },
  },
  activated() {
    this.scrollY(this.scrolltop);
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.wrapper.removeEventListener("scroll", this.__onPageScroll);
      this.$refs.wrapper.addEventListener("scroll", this.__onPageScroll);
    });
  },
  methods: {
    __onPageScroll: function (evt) {
      this.scrolltop = evt.target.scrollTop;
      let scrollTop = evt.target.scrollTop; //内容滚动位置
      let scrollHeight = evt.target.scrollHeight; //内容实际高度
      let offsetHeight = evt.target.offsetHeight; //可见的高度
      let sopHeight = Math.ceil(scrollTop + offsetHeight);
      this.$emit("scroll", evt);
      if (scrollHeight > offsetHeight && sopHeight >= scrollHeight) {
        // 滚动到底部
        if (!this.reachBottom) {
          this.reachBottom = true;
          this.$emit("scroll-bottom", this);
          setTimeout(() => {
            this.reachBottom = false;
          }, 600);
        }
      } else if (scrollTop <= 0) {
        //   滚动到顶部
        this.$emit("scroll-top", this);
      }
    },
    // 页面下拉拖拽动画
    __touchstart(evt) {
      this.startY = evt.targetTouches[0].pageY;
      if (this.scrolltop == 0 && this.pulldown) {
        this.renewTxt = "下拉刷新";
        this.transition = false;
        this.startDrag = true;
        this.$refs.wrapper.addEventListener("touchmove", this.__touchmove);
        this.$refs.wrapper.addEventListener("touchend", this.__touchend);
      }
    },
    __touchmove(evt) {
      //   只有滚动到了顶部，在可以下拉拖动
      if (this.startDrag && this.scrolltop == 0) {
        this.dragY = evt.targetTouches[0].pageY - this.startY;
        if (this.dragY > 0) {
          this.scrolling = false;
          this.isPulldown = true;
        } else {
          this.scrolling = true;
          this.isPulldown = false;
        }
        if (this.dragY > 0 && this.dragY < this.dragArea.height * 2) {
          this.$refs.wrapper.style.top = this.dragY / 2 + "px";
          let opacity = this.dragY / this.dragArea.dist / 2;
          this.threshold = opacity > 1 ? 1 : opacity;
          if (this.threshold == 1) {
            this.renewTxt = "释放更新";
          }
        } else if (this.dragY <= 0) {
          this.__renewScrollY();
          this.threshold = 0;
        } else if (this.dragY >= 50) {
          this.threshold = 1;
        }
      } else {
        this.startDrag = false;
      }
    },
    __touchend() {
      this.$refs.wrapper.removeEventListener("touchmove", this.__touchmove);
      this.$refs.wrapper.removeEventListener("touchend", this.__touchend);
      this.transition = true;
      if (this.dragY >= this.dragArea.dist * 2) {
        this.$emit("pull-down", this);
      } else if (this.dragY > 0) {
        this.scrollY(0);
      }
      this.__renewScrollY();
      this.startDrag = false;
    },
    __renewScrollY() {
      setTimeout(() => {
        this.$refs.wrapper.style.top = "0";
        this.threshold = 0;
        this.dragY = 0;
        setTimeout(() => {
          this.scrolling = true;
          this.isPulldown = false;
        }, 400);
      }, 1);
    },
    scrollY(val) {
      this.$refs.wrapper.scrollTo && this.$refs.wrapper.scrollTo(0, val);
    },
    scrollYBy(val) {
      if (this.$refs.wrapper && this.scrolltop > 0) {
        this.__scrollToY(this.scrolltop + val, this.$refs.wrapper);
      }
    },
    __scrollToY(y, emt, duration = 300) {
      if (emt.scrollTop === y) return;

      const cosParameter = (emt.scrollTop - y) / 2;
      let scrollCount = 0;
      let oldTimestamp = null;

      function step(newTimestamp) {
        if (oldTimestamp !== null) {
          scrollCount += (Math.PI * (newTimestamp - oldTimestamp)) / duration;
          if (scrollCount >= Math.PI) return (emt.scrollTop = y);
          emt.scrollTop =
            cosParameter + y + cosParameter * Math.cos(scrollCount);
        }
        oldTimestamp = newTimestamp;
        window.requestAnimationFrame(step);
      }
      window.requestAnimationFrame(step);
    },
  },
};
</script>
<style lang="less" scoped>
.rx_wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .wrapper {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    overscroll-behavior-y: none;
    &.scrolling {
      -webkit-overflow-scrolling: touch;
    }
    &.transition {
      transition: top 0.4s;
    }
    &.isPulldown {
      overflow: hidden;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    &.hideBar {
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .pulldownBackground {
    position: absolute;
    text-align: center;
    top: 0;
    left: 0;
    width: 100%;
    font-size: 13px;
    line-height: 50px;
    color: #666666;
    span {
      display: inline-block;
    }
  }
  .nomore {
    position: relative;
    width: 100%;
    text-align: center;
    line-height: 70px;
    font-size: 13px;
    color: #666666;
  }
}
</style>
