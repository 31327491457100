<template>
  <rx-view class="homeCompany" :loading="isLoading('getBestCompany')">
    <item-company
      class="citem"
      :type="type[item.chainId]"
      :info="item"
      v-for="(item, index) in items"
      :key="index"
    ></item-company>
    <!-- <div class="changeBtn"></div> -->
  </rx-view>
</template>
<script>
import mixinBase from "@src/components/mixins/mixinBase";
export default {
  name: "home-company",
  mixins: [mixinBase],
  data() {
    return {
      type: {
        "0400000000": "qc",
        "0100000000": "swyy",
        "0200000000": "rgzn",
        "0300000000": "g5",
      },
      items: [],
    };
  },
  created() {
    A.updata.getBestCompany().then((res) => {
      this.items = res.data;
    });
  },
  mounted() {},
  methods: {
    change() {},
  },
};
</script>
<style lang="less" scoped>
.homeCompany {
  max-width: 1200px;
  margin: auto;
  height: 660px;
  position: relative;
  .citem {
    float: left;
    &:nth-child(2n) {
      margin-left: 20px;
    }
  }
  .changeBtn {
    display: inline-block;
    position: absolute;
    top: -35px;
    right: 0;
    width: 132px;
    height: 35px;
    overflow: hidden;
    cursor: pointer;
    &::before {
      content: "点击换一批";
      position: absolute;
      top: 0;
      right: 0;
      width: 132px;
      height: 50px;
      background: linear-gradient(90deg, #ffb027 0%, #ffae00 100%);
      border-radius: 50px 10px 0px 0px;
      color: #ffffff;
      line-height: 35px;
      padding-left: 32px;
      box-sizing: border-box;
      text-align: center;
    }
    &:hover {
      &::before {
        background: linear-gradient(90deg, #f87b00 0%, #ffa800 100%);
      }
    }
  }
}
</style>