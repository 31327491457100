<template>
  <el-form class="homePublish" ref="form" :model="form" :rules="rules" label-width="90px">
    <el-form-item label="采购产品" prop="productName">
      <el-input v-model="form.productName" placeholder="请输入您采购的产品名称"></el-input>
    </el-form-item>
    <el-form-item label="产品分类" prop="categoryCode">
      <el-cascader v-model="form.categoryCode" :options="levelList" :props="prodProps"
        placeholder="选择产品分类，让供应商快速匹配您的需求（非必须）">
      </el-cascader>
    </el-form-item>
    <el-form-item label="采购要求" prop="illustration">
      <el-input type="textarea" placeholder="详细描述您的采购要求，为您推荐更多匹配需求的供应商" v-model="form.illustration" maxlength="2000"
        show-word-limit resize='none'>
      </el-input>
    </el-form-item>
    <publish-phone v-model="logindata" utype="buy" ref="login"></publish-phone>
    <el-form-item>
      <div class="vx-flex">
        <div class="cancel">
          <el-button class="button" @click="$emit('click-cancel')">取消发布</el-button>
        </div>
        <div class="vx-flex_item">
          <el-button type="primary" @click="onSubmit" :loading="isput">立即发布采购需求</el-button>
        </div>
      </div>
    </el-form-item>
  </el-form>
</template>
<script>
  import {
    mapState
  } from "vuex";
  import AppraiseItem from "@/web/ucenter/components/appraiseItem";
  import PublishPhone from "../components/publishPhone";
  export default {
    name: "demand-publish-buy",
    components: {
      AppraiseItem,
      PublishPhone
    },
    props: {
      title: {
        type: String,
        default: "",
      },
      levelList: {
        type: Array,
        default () {
          return [];
        },
      },
    },
    data() {
      return {
        isput: false,
        isopen: false,
        logindata: {},
        prodProps: {
          value: "cid",
          label: "name",
          children: "children",
        },
        form: {
          productName: "",
          categoryCode: "",
          illustration: "",
          superiority: [],
        },
        rules: {
          productName: [{
            required: true,
            message: " 请填写采购产品",
            trigger: "blur",
          }, ],
          categoryCode: [{
            required: true,
            message: "请选择产品分类",
            trigger: "change",
          }, ],
          illustration: [{
            required: true,
            message: "请填写采购要求",
            trigger: "change",
          }, ],
        },
      };
    },
    watch: {
      title: {
        handler(val) {
          this.form.productName = val;
        },
        immediate: true,
      },
    },
    computed: {
      ...mapState("user", {
        info: (state) => state.info,
        token: (state) => state.token,
      }),
    },
    created() {},
    methods: {
      open() {
        this.isopen = true;
      },
      changeDesc(val) {
        this.form.superiority = val;
      },
      onSubmit() {
        cuAtion.sendClick('立即发布采购需求')
        this.timeover(true);
        this.$refs.login.valid();
        this.$refs.form.validate((valid) => {
          if (valid) {
            if (this.token) {
              this.isput = true;
              this.publish();
            } else if (
              this.logindata.mobile &&
              this.logindata.code &&
              this.logindata.checked
            ) {
              this.isput = true;
              let params = {
                mobile: this.logindata.mobile,
                code: this.logindata.code,
                type: "DemandPurchasePublish",
              };
              A.loginAndInfo(params).then(
                (res) => {
                  this.publish();
                },
                (err) => {
                  this.timeover();
                }
              );
            }
          } else {
            this.timeover();
            return false;
          }
        });
      },
      publish() {
        let _data = Object.assign({}, this.form);
        if (_data.categoryCode instanceof Array) {
          _data.categoryCode = _data.categoryCode[2];
        }
        A.updata.publishPurchase(_data).then(
          (res) => {
            location.href = "/resources/purchase?id=" + res.data;
            this.timeover();
          },
          (err) => {
            this.timeover();
            A.message("发布失败，请重试");
          }
        );
      },
      timeover(val) {
        if (this.it) {
          clearTimeout(this.it);
          this.isput = false;
          this.it = null;
        }
        if (val) {
          this.it = setTimeout(() => {
            this.isput = false;
            this.it = null;
          }, 16000);
        }
      },
    },
  };
</script>
<style lang="less" scoped>
  @import url("./homePublish.less");

  /deep/.el-textarea__inner {
    border-radius: 0;
    height: 185px;
  }
</style>