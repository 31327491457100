<template>
  <plane-home
    h2="专业咨询"
    h4="应用科学方法 提升企业管理水平和经济效益"
    cheight="450px"
  >
    <rx-view class="homeConsult" :loading="isLoading('expertListRecommend')">
      <div class="vx-flex_item">
        <div class="about vx-flex">
          <div class="cpImg">
            <a
              :href="'/exchange/expertHomepage?expertId=' + info.expertId"
              target="_blank"
              data-ulog="查看行家"
            >
              <img :src="info.userLogo" />
            </a>
          </div>
          <div class="cpAbout">
            <h2>
              <a
                class="hover"
                :href="'/exchange/expertHomepage?expertId=' + info.expertId"
                target="_blank"
                data-ulog="查看行家"
                >{{ info.userName }}</a
              >
            </h2>
            <div>
              <rx-utag :label="info.expertLabel"></rx-utag>
            </div>
            <div class="ellipsis about_con">
              {{ info.introduce }}
            </div>
          </div>
        </div>
        <div class="list">
          <div
            class="cpItem"
            :class="{ act: i == index }"
            :style="{ backgroundImage: 'url(' + item.userLogo + ')' }"
            v-for="(item, index) in items"
            :key="index"
            @mouseover="selectItem(index)"
            @mouseout="changePlay(true)"
          ></div>
        </div>
      </div>
      <a
        :href="'/exchange/expertHomepage?expertId=' + info.expertId"
        target="_blank"
        class="yuyueBtn"
        data-ulog="查看行家"
        >预约咨询</a
      >
    </rx-view>
  </plane-home>
</template>
<script>
import { mapState } from "vuex";
import mixinBase from "@src/components/mixins/mixinBase";
export default {
  name: "home-consult",
  mixins: [mixinBase],
  computed: {
    ...mapState("global", {
      utag: (state) => state.utag,
    }),
  },
  data() {
    return {
      i: 0,
      info: {},
      items: [],
    };
  },
  created() {
    A.updata.expertListRecommend().then((res) => {
      if (res.data) {
        if (res.data.length > 5) {
          this.items = res.data.slice(0, 5);
        } else {
          this.items = res.data;
        }
        this.info = this.items[0];
        this.changePlay(true);
      }
    });
  },
  mounted() {},
  methods: {
    selectItem(val) {
      this.i = val;
      this.info = this.items[val];
      this.changePlay(false);
    },
    changePlay(play = true) {
      if (this.it) {
        clearInterval(this.it);
        this.it = null;
      }
      if (play) {
        this.it = setInterval(() => {
          if (this.i < this.items.length - 1) {
            this.i++;
          } else {
            this.i = 0;
          }
          this.info = this.items[this.i];
        }, 3000);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.homeConsult {
  position: relative;
  padding: 25px;
  color: #666666;
  .about {
    height: 270px;
    margin-bottom: 20px;
    .cpImg {
      img {
        width: 270px;
        height: 270px;
        background: #ffffff;
        overflow: hidden;
      }
    }
    .cpAbout {
      padding: 0 0 20px 18px;
    }
    .tag_a {
      display: inline-block;
      color: #1d44a6;
      font-size: 12px;
      padding: 0 10px;
      line-height: 23px;
      height: 23px;
      background: #edf1f8;
      margin-right: 10px;
    }
    .about_con {
      height: 184px;
      line-height: 23px;
      font-size: 14px;
      -webkit-line-clamp: 8;
      margin-top: 20px;
    }
  }
  .list {
    padding: 15px 0;
    .cpItem {
      width: 85px;
      height: 85px;
      background-color: #ffffff;
      background-image: url("../assets/timg.jpg");
      background-size: 100%;
      background-position: center;
      box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.14);
      margin-right: 28px;
      position: relative;
      transition: transform 0.6s;
      display: inline-block;
      top: 0;
      &:last-child {
        margin: 0;
      }
      &.act {
        border: 2px solid #ff7a00;
        box-shadow: 0px 3px 10px 0px rgba(216, 130, 0, 0.2);
        transform: translateY(-5px);
        &::after {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          border: 5px solid transparent;
          border-bottom: 9px solid#ff7a00;
          left: calc(50% - 4px);
          top: -16px;
        }
      }
    }
  }

  .yuyueBtn {
    display: inline-block;
    position: absolute;
    height: 35px;
    line-height: 35px;
    border-radius: 4px;
    padding: 0 22px;
    right: -8px;
    top: 20px;
    background-color: #ffa701;
    color: #ffffff;
    font-size: 16px;
    text-align: center;
    &::after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border: 3px solid transparent;
      border-top: 3px solid #666666;
      border-left: 3px solid#666666;
      bottom: -6px;
      right: 3px;
    }
    &:hover {
      background: linear-gradient(90deg, #f87b00 0%, #ffa800 100%);
    }
  }
}
</style>