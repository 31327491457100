<script>
import rxTopLogo from "@/components/topLogo";
import rxView from "@/components/view";
import SearchInput from "./components/searchInput";
export default {
  el: "#topNav",
  name: "topNav",
  data: {},
  created() {},
  methods: {},
  components: { rxTopLogo, SearchInput, rxView },
};
</script>

<style lang="less">
#topNav {
  max-width: 1200px;
  margin: auto;
  padding-top: 28px;
  box-sizing: border-box;
  .search {
    padding-top: 4px;
  }
}
</style>
