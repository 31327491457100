<template>
  <div class="searchInput" :class="{ptop:hideTopBar}">
    <el-select class="select" v-model="selectValue" placeholder="请选择">
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option> </el-select
    ><rx-search-bar width="512px" :placeholder="selectValue=='找企业'?'搜索您想查找的企业':'搜索您想查找的需求'" @click-search="search"></rx-search-bar>
    <div class="newdemand" :class="{show:!expertInfo.expertId}" @click="publish" data-ulog>发布需求</div>
    <div class="hotkeys" v-if="!hideTopBar">
      <span
        class="hotkey"
        @click="search(v.itemTitle)"
        v-for="(v, i) in hotkey"
        :key="i"
        >{{ v.itemTitle }}</span
      >
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import rxSearchBar from "@/components/searchBar";
export default {
  name: "search-input",
  components: { rxSearchBar },
  computed: {
    ...mapState("user", {
      expertInfo: (state) => state.expertInfo,
    }),
    ...mapState("global", {
      hideTopBar: (state) => state.hideTopBar,
    }),
  },
  data() {
    return {
      options: [
        {
          value: "找企业",
          label: "找企业",
        },
        {
          value: "找需求",
          label: "找需求",
        },
      ],
      selectValue: "找企业",
      hotkey: [],
    };
  },
  watch: {
    selectValue(newVal) {
      cuAtion.sendClick("搜索范围",newVal)
      this.loadhotKey();
    },
  },
  created() {
    this.loadhotKey();
  },
  methods: {
    loadhotKey() {
      if (this.selectValue == "找企业") {
        A.updata
          .getPageItem({
            areaCode: "pc.index.search.hot.key.company",
            limit: 5,
          })
          .then((res) => {
            this.hotkey = res.data;
          });
      } else {
        A.updata
          .getPageItem({ areaCode: "pc.index.search.hot.key.demand", limit: 5 })
          .then((res) => {
            this.hotkey = res.data;
          });
      }
    },
    search(value) {
      cuAtion.sendClick("热搜词",value)
      if (this.selectValue == "找企业") {
        location.href = "/resources?keyword=" + value;
      } else {
        location.href = "/resources/market?keyword=" + value;
      }
    },
    publish() {
      publish("sale");
    },
  },
};
</script>
<style lang="less" scoped>
.searchInput {
  font-size: 0;
  font-size: 16px;
  margin-left: auto;
  &.ptop{
    padding-top: 6px;
  }
  .select {
    width: 88px;
    height: 40px;
    background: #dee7f3;
    border-radius: 3px 0 0 3px;
    vertical-align: bottom;
    /deep/.el-input__inner {
      border: none;
      background-color: transparent;
      color: #275aed;
    }
    /deep/.el-icon-arrow-up:before {
      content: "\e78f";
      color: #275aed;
    }
  }
  .newdemand {
    margin-left: 10px;
    display: none;
    width: 130px;
    height: 40px;
    background: #ffae00;
    text-align: center;
    color: #ffffff;
    line-height: 40px;
    vertical-align: bottom;
    font-size: 16px;
    cursor: pointer;
    &.show {
      display: inline-block;
    }
    &:hover {
      background: #e29d07;
    }
  }
  .hotkeys {
    font-size: 14px;
    color: #888888;
    padding-left: 100px;
    line-height: 3;
    text-align: left;
    span {
      padding-left: 12px;
    }
  }
  .hotkey {
    cursor: pointer;
    &:hover {
      color: #275aed;
    }
  }
}
</style>