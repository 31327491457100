<template>
  <el-form class="homePublish" ref="form" :model="form" :rules="rules" label-width="90px">
    <el-form-item label="销售产品" prop="productName">
      <el-input v-model="form.productName" placeholder="请输入您销售的产品名称"></el-input>
    </el-form-item>
    <el-form-item label="产品分类" prop="categoryCode">
      <el-cascader v-model="form.categoryCode" :options="levelList" :props="prodProps"
        placeholder="选择产品分类，让采购商快速找到您的产品">
      </el-cascader>
    </el-form-item>
    <el-form-item label="产品优势" prop="superiority">
      <AppraiseItem @changeDesc="changeDesc"></AppraiseItem>
    </el-form-item>
    <el-form-item label="企业名称" prop="company">
      <el-input v-model="form.company" placeholder="输入您所在的企业名称，让采购商对您的企业更加了解"></el-input>
    </el-form-item>
    <publish-phone v-model="logindata" utype="sale" ref="login"></publish-phone>
    <el-form-item>
      <div class="vx-flex">
        <div class="cancel">
          <el-button class="button" @click="$emit('click-cancel')">取消发布</el-button>
        </div>
        <div class="vx-flex_item">
          <el-button type="primary" @click="onSubmit" :loading="isput">立即发布销售需求</el-button>
        </div>
      </div>
    </el-form-item>
  </el-form>
</template>
<script>
  import {
    mapState
  } from "vuex";
  import AppraiseItem from "@/web/ucenter/components/appraiseItem";
  import PublishPhone from "../components/publishPhone";
  export default {
    name: "demand-publish-sale",
    components: {
      AppraiseItem,
      PublishPhone
    },
    props: {
      title: {
        type: String,
        default: "",
      },
      levelList: {
        type: Array,
        default () {
          return [];
        },
      },
    },
    data() {
      return {
        isput: false,
        isopen: false,
        logindata: {},
        prodProps: {
          value: "cid",
          label: "name",
          children: "children",
        },
        form: {
          productName: "",
          categoryCode: "",
          company: "",
          superiority: [],
        },
        rules: {
          productName: [{
            required: true,
            message: "请填写销售产品",
            trigger: "blur",
          }, ],
          categoryCode: [{
            required: true,
            message: "请选择产品分类",
            trigger: "change",
          }, ],
          superiority: [{
            required: true,
            message: " 请填写产品优势",
            trigger: "change",
          }, ],
          company: [{
            required: true,
            message: "请填写企业名称",
            trigger: "change",
          }, ],
        },
      };
    },
    watch: {
      title: {
        handler(val) {
          this.form.productName = val;
        },
        immediate: true,
      },
    },
    computed: {
      ...mapState("user", {
        info: (state) => state.info,
        token: (state) => state.token,
      }),
    },
    created() {
      if (this.token) {
        A.updata.enterpriseList({
          data: 1
        }).then((res) => {
          if (res.data.length > 0) {
            this.form.company = res.data[0].enterprise;
          }
        });
      }
    },
    methods: {
      open() {
        this.isopen = true;
      },
      changeDesc(val) {
        this.form.superiority = val;
      },
      onSubmit() {
        cuAtion.sendClick('立即发布销售需求')
        this.timeover(true);
        this.$refs.login.valid();
        this.$refs.form.validate((valid) => {
          if (valid) {
            if (this.token) {
              this.isput = true;
              this.publish();
            } else if (
              this.logindata.mobile &&
              this.logindata.code &&
              this.logindata.checked
            ) {
              this.isput = true;
              let params = {
                mobile: this.logindata.mobile,
                code: this.logindata.code,
                type: "DemandSalePublish",
              };
              A.loginAndInfo(params).then(
                (res) => {
                  this.publish();
                },
                (err) => {
                  this.timeover();
                }
              );
            }
          } else {
            this.timeover();
            return false;
          }
        });
      },
      publish() {
        let _data = Object.assign({}, this.form);
        if (_data.categoryCode instanceof Array) {
          _data.categoryCode = _data.categoryCode[2];
        }
        A.updata.publishSale(_data).then(
          (res) => {
            location.href = "/resources/selling?id=" + res.data;
            this.timeover();
          },
          (err) => {
            this.timeover();
            A.message("发布失败，请重试");
          }
        );
      },
      timeover(val) {
        if (this.it) {
          clearTimeout(this.it);
          this.isput = false;
          this.it = null;
        }
        if (val) {
          this.it = setTimeout(() => {
            this.isput = false;
            this.it = null;
          }, 16000);
        }
      },
    },
  };
</script>
<style lang="less" scoped>
  @import url("./homePublish.less");
</style>