import PTitle from "./pTitle"
import ItemActivity from './itemActivity'
import ItemCompany from './itemCompany2'
import ItemDemand from './itemDemand'
import PlaneHome from './planeHome'

export default [
    PTitle,
    ItemActivity,
    ItemCompany,
    ItemDemand,
    PlaneHome
]