<template>
  <div class="topLogo">
    <span class="logo" @click="gohome"></span>
    <span class="line"></span>
    <span v-if="title">{{title}}</span>
  </div>
</template>
<script>
export default {
  name: "rx-top-logo",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  methods: {
    gohome() {},
  },
};
</script>
<style lang="less" scoped>
.topLogo {
  position: relative;
  display: inline-block;
  font-size: 24px;
  span {
    display: inline-block;
  }
  .line {
  }
}
</style>