  import Vue from 'vue';
  import VipAlert from './vipAlert.vue';
  let alertBox = Vue.extend(VipAlert);
  var instance
  const vipAlert = function (title, content) {

    instance = new alertBox({
      data: {
        title,
        content
      }
    });
    instance.$mount();
    document.body.appendChild(instance.$el);
  }

  export default vipAlert