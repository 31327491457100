<template>
  <div class="pageTop" :class="{fixed:fixed}" :style="styleObj">
    <header class="header vx-flex" :style="headerStyle">
      <div class="left" v-if="showLeft">
        <i @click="back" :class="[button]" v-if="button!=''"></i>
      </div>
      <div class="left" v-if="type=='root'">
        <span class="main-icon" @click="openList"></span>
      </div>
      <div class="vx-flex_item">
        <span class="logo-top" @click="goHome"></span>
      </div>
      <div class="right">
        <div class="right-box" v-if="showRight">
          <img v-if="info&&info.userLogo" :src="info.userLogo" alt="" @click="offline">
          <div v-else class="login-icon" @click="goLogin">登录</div>
        </div>
      </div>
    </header>
    <cube-popup type="my-popup" position="left" :mask-closable="true" ref="menuPopup">
      <div class="popup-box">
        <div class="popup-item" v-for="(item, i) in menu" :key="i" @click="goLink(item.link,item.text)">{{item.text}}</div>
      </div>
    </cube-popup>
  </div>
</template>
<script>
import { mapState } from "vuex";

var topHeight = 44;
export default {
  name: "page-header",
  components: {

  },
  props: {
    title: {
      type: String,
      default: ""
    },
    fixed: {
      type: Boolean,
      default: false
    },
    syl: {
      type: Object
    },
    ibtn: {
      type: String,
      default: "back"
    },
    bn: {
      type: Number,
      default: -1
    },
    hide: {
      type: Array,
      default() {
        return [];
      }
    },
    type: {
      type: String,
    }
  },
  computed: {
    showLeft() {
      return this.hide.find(n => n == "left") ? false : true;
    },
    showRight() {
      return this.hide.find(n => n == "right") ? false : true;
    },
    button() {
      return "cubeic-" + this.ibtn;
    },
    ...mapState("compatible", {
      DVCTop: state => state.dvcTop
    }),
    info() {
      return A.db.user || '';
    },
    token() {
      return A.db.token || '';
    }
  },
  data() {
    return {
      menu: [
        { text: '首页', link: '/home'},
        { text: '需求市场', link: '/market'},
        { text: '企业库', link: '/company'},
        { text: '政策智配', link: '/policy'},
        { text: '产业服务', link: '/service'},
        { text: '融象产盟APP', link: 'https://www.rongxiangipo.com/zt/20200522/index.html?adsChannel=1054&f=1'},
      ],
      styleObj: {
        height: topHeight + "px",
        color: "#333333"
      },
      headerStyle: {
        height: topHeight + "px",
        lineHeight: topHeight + "px"
      },
      show: false,
      topHeight: 44,
    };
  },
  watch: {
    $route() {},
    syl: {
      handler(val) {
        Object.assign(this.styleObj, val);
      }
    }
  },
  created() {
    Object.assign(this.styleObj, this.syl);
  },
  methods: {
    hideMenu() {
      this.$refs.menuPopup.hide();
    },
    back() {
      this.$router.goBack(this.bn);
    },
    openList() {
      A.cuAtion.sendClick("菜单");
      this.$refs.menuPopup.show();
    },
    goLink(url,text) {
      A.cuAtion.sendClick("菜单-"+text);
      if (url == this.$route.path) {
        this.$router.go(0);
        return;
      }
      if (url.indexOf("/")==0) {
        this.$router.push(url)
      } else {
        window.location.href = url;
      }  
    },
    goLogin() {
      A.cuAtion.sendClick("登录");
      this.$router.push({path: "/login?isback=1"});
    },
    offline() {
      A.cuAtion.sendClick("头像");
      this.$createActionSheet({
        active: 0,
        data: [
          {
            content: "退出登录"
          }
        ],
        onSelect: (item, index) => {
          A.cuAtion.sendClick("退出登录");
          this.loginOut();
        },
        onCancel: () => {}
      }).show();
    },
    loginOut() {
      A.db.logout();
      A.store.commit("user/clear", '');
      A.db.user = {};
      A.db.token = "";
      setTimeout(()=>{
        this.$router.push('/login');
      },300);
    },
    goHome() {
      if (this.$route.path != '/home') {
        this.$router.push('/home');
      }
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.cube-popup-left .cube-popup-content {
  background: #2B2B2B;
  height: auto;
  margin-top: 44px;
}
/deep/ .cubeic-back {
  color: #2659ED;
}
.popup-box {
  display: flex;
  flex-direction: column;
  .popup-item {
    height: 45px;
    line-height: 45px;
    color: #FFFFFF;
    font-size: 12px;
    width: 140px;
    padding-left: 18px;
  }
  .popup-item+.popup-item {
    border-top: 1px solid #666666;
  }
}
.vx-flex {
  display: flex;
}
.pageTop {
  position: relative;
  background-color: #ffffff;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  box-shadow: 0px 0px 6px 1px rgba(51, 51, 51, 0.05);
  box-sizing: border-box;
  z-index: 111;
  .vx-flex_item {
    flex: 1;
  }
  &.border {
    box-shadow: none;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 200%;
      height: 1px;
      border-bottom: 1px #f0f0f0 solid;
      transform: scale(0.5);
      transform-origin: 0 bottom;
    }
  }
  &.none {
    box-shadow: 0px 0px 0px 0px rgba(51, 51, 51, 0);
  }
  &.fixed {
    position: fixed;
    width: 100%;
  }
  .header {
    position: relative;
    box-sizing: border-box;
    height: 50px;
    line-height: 50px;
    text-align: center;
    z-index: 5;
    .left {
      width: 70px;
    }
    .main-icon {
      background: url("../../assets/img/left_icon.png") no-repeat;
      background-size: 100%;
      width: 20px;
      height: 20px;
      display: inline-block;
      position: absolute;
      top: 50%;
      margin-top: -10px;
      left: 15px;
    }
    .logo-top {
      width: 94px;
      height: 27px;
      display: inline-block;
      background: url("./../../../wap/assets/img/logo_top.png") no-repeat;
      background-size: 100% 100%;
      vertical-align: middle;
    }
    h1 {
      font-size: 16px;
      font-weight: 700;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      margin: 0;
    }
    [class^="cubeic-"] {
      position: absolute;
      top: 0;
      left: 0;
      padding: 0 15px;
    }
    .right {
      min-width: 70px;
      position: relative;
      text-align: right;
    }
    .right-box {
      position: relative;
      height: 44px;
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      padding-right: 15px;
      img {
        width: 21px;
        height: 21px;
        border-radius: 11px;
      }
      .login-icon {
        width: 50px;
        height: 22px;
        line-height: 22px;
        color: #333333;
        border-radius: 11px;
        border: 1px solid #333333;
        text-align: center;
        font-size: 12px;
      }
    }
  }
}
</style>