<template>
  <div class="homePlaneSever">
    <div class="svs">
      <div class="svsTop">
        <div class="h02">产业资源链接</div>
        <div>获取精准的客户线索 扩展市场空间</div>
        <img src="../assets/sc01.png" />
      </div>
      <div class="vx-flex svMainItem">
        <div class="tagimg">
          <img src="../assets/sc03.png" />
        </div>
        <div class="text">
          <div class="h03">产业拓客</div>
          <div>产业大数据，智能追踪客户线索</div>
        </div>
      </div>
      <div class="vx-flex svMainItem">
        <div class="tagimg">
          <img src="../assets/sc07.png" />
        </div>
        <div class="text">
          <div class="h03">精准搜客</div>
          <div>多维企业信息，组合任意搜</div>
        </div>
      </div>
      <div class="vx-flex svMainItem">
        <div class="tagimg">
          <img src="../assets/sc04.png" />
        </div>
        <div class="text">
          <div class="h03">资源对接</div>
          <div>十年服务经验，以资源换产业，以产业促资源</div>
        </div>
      </div>
    </div>
    <div class="svs right">
      <div class="svsTop">
        <div class="h02">产业资本服务</div>
        <div>专业的投融对接 促进上市加速</div>
        <img src="../assets/sc02.png" />
      </div>
      <div class="vx-flex svMainItem">
        <div class="tagimg">
          <img src="../assets/sc06.png" />
        </div>
        <div class="text">
          <div class="h03">投融对接</div>
          <div>36w+投资人资源优势，促进企业股权投融资对接</div>
        </div>
      </div>
      <div class="vx-flex svMainItem">
        <div class="tagimg">
          <img src="../assets/sc05.png" />
        </div>
        <div class="text">
          <div class="h03">上市加速</div>
          <div>“一对一”定制上市加速服务，让企业科学、高效的进入资本市场</div>
        </div>
      </div>
    </div>
    <div class="svsCenter">
      <div class="vx-flex" style="margin-bottom:50px;">
        <div class="vx-flex_item">
          <h2 class="h02">产业政企服务</h2>
          <div>入驻产业园区，速配海量政策， 申报资金扶持</div>
        </div>
        <div style="padding-left:50px">
          <img src="../assets/sc09.png" />
        </div>
      </div>
      <div class="vx-flex svMainItem">
        <div class="tagimg">
          <img src="../assets/sc15.png" />
        </div>
        <div class="text">
          <div class="h03">园区入驻</div>
          <div>协助企业办公选址，一站式规划工商财税</div>
        </div>
      </div>
      <div class="vx-flex svMainItem">
        <div class="tagimg">
          <img src="../assets/sc16.png" />
        </div>
        <div class="text">
          <div class="h03">政策倾斜</div>
          <div>专业团队解读，算法智配政策，全方位 申报提醒</div>
        </div>
      </div>
      <div class="vx-flex svMainItem">
        <div class="tagimg">
          <img src="../assets/sc17.png" />
        </div>
        <div class="text">
          <div class="h03">企业扶持</div>
          <div>一体化企业服务，提升企业品牌，补贴 财政收入</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "home-plane-sever",
  data() {
    return {
      tab: 0,
    };
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.homePlaneSever {
  position: relative;
  max-width: 1200px;
  height: 510px;
  margin: auto;
  margin-bottom: 20px;
  .svs {
    position: absolute;
    left: 0;
    top: 33px;
    display: block;
    width: 408px;
    height: 448px;
    background: #ffffff;
    border: 1px solid #eeeeee;
    box-shadow: 0px 10px 20px 0px #f2f2f2;
    &.right {
      left: auto;
      right: 0;
    }
    .svsTop {
      position: relative;
      height: 127px;
      background: linear-gradient(-55deg, #eef2ff, #ffffff);
      padding: 24px;
      border-bottom: 1px #eeeeee solid;
      color: #666666;
      img {
        position: absolute;
        top: 30px;
        right: 40px;
      }
    }
  }
  .svMainItem {
    padding: 21px 26px;
    font-size: 14px;
    .h03 {
      padding-bottom: 12px;
      line-height: 1;
    }
    .tagimg {
      padding-right: 21px;
    }
  }
  .svsCenter {
    position: absolute;
    left: 408px;
    top: 0;
    display: block;
    width: 386px;
    height: 510px;
    background: #2659ed;
    background-image: url(../assets/svsbg.png);
    box-shadow: 0px 10px 20px 0px rgba(38, 89, 237, 0.2);
    color: #ffffff;
    padding: 50px 35px;
    .svMainItem {
      color: #ffffff;
      padding: 21px 0;
      padding-bottom: 5px;
    }
    .h02 {
      color: #ffffff;
    }
    .h03 {
      color: #ffffff;
    }
  }
}
</style>