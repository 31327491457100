<template>
  <plane-home h2="产业资讯" h4="探究行业热点 掌握最新动态" cheight="660px">
    <rx-view class="homeNews" :loading="isLoading('newsRecentList')">
      <a :href="'/exchange/newsDetail?id='+v.newsId" target="_blank" class="newItem vx-flex" v-for="v in items" :key="v.newsId"  data-ulog="查看资讯">
        <div class="image" v-if="v.coverImage">
          <rx-img height="180px" width="250px" class="itemImg" :src="v.coverImage"></rx-img>
        </div>
        <div class="vx-flex_item">
          <h3 class="hover ellipsis" :class="{c2:v.coverImage}">
            {{ v.title }}
          </h3>
          <div class="des ellipsis c3">
            {{ v.summary }}
          </div>
          <div class="tags">
            <span class="tag org">{{ v.category }}</span>
            <template v-for="(val, i) in v.tag">
              <span :key="i" class="tag" v-if="val&&i<3">{{ val }}</span>
            </template>
          </div>
          <span class="time">{{fmtTime(v.publishTime)}}</span>
        </div>
      </a>
    </rx-view>
  </plane-home>
</template>
<script>
import mixinBase from "@src/components/mixins/mixinBase";
export default {
  name: "home-news",
  mixins: [mixinBase],
  data() {
    return {
      i: 0,
      items: [],
    };
  },
  created() {
    A.updata.newsRecentList({ limit: 3 }).then((res) => {
      this.items = res.data;
    });
  },
  mounted() {},
  methods: {
    fmtTime(val){
      return A.momentCalendar(val);
    },
    selectItem(val) {
      this.i = val;
    },
  },
};
</script>
<style lang="less" scoped>
.homeNews {
  position: relative;
  padding: 28px;
  color: #666666;
  .newItem {
    position: relative;
    margin-bottom: 35px;
    cursor: pointer;
    h3 {
      font-size: 16px;
    }
    .image {
      margin-right: 19px;
      height: 180px;
      width: 250px;
      overflow: hidden;
      .itemImg {
        transition: transform 0.6s;
      }
      &:hover {
        .itemImg {
          transform: scale(1.1);
        }
      }
    }
    }
    .des {
      margin: 14px 0;
      line-height: 24px;
      color: #666666;
      height: 72px;
    }
    .tag {
      margin-right: 10px;
      margin-bottom: 5px;
    }
    .time{
      position: absolute;
      right: 0;
      bottom: 0;
      color: #999999;
    }
}
</style>