<template>
  <plane-home h2="行业研报" h4="把握行业竞争先机" cheight="660px">
    <rx-view class="homeTech" :loading="isLoading('getHomeReport')">
      <a
        :href="'/exchange/reportDetail?reportId=' + item.reportId"
        target="_blank"
        class="techItem vx-flex"
        v-for="(item, index) in items"
        :key="index"
        data-ulog="查看研报"
      >
        <div class="image">
          <rx-img
            height="125px"
            width="100px"
            class="itemImg"
            :src="item.reportLogo"
          ></rx-img>
        </div>
        <div class="vx-flex_item" style="position: relative">
          <h4 class="ellipsis c2 hover">
            {{ item.reportName }}
          </h4>
          <div class="tags" v-if="item.reportLabelNames">
            <template v-for="(v, i) in item.reportLabelNames.split(',')">
              <span class="tag" :key="v.reportId" v-if="i < 2">{{ v }}</span>
            </template>
          </div>
          <div class="vx-flex ename">
            <div class="vx-flex_item name">{{ item.reportSource }}</div>
            <div>{{ item.viewNum }}人已阅读</div>
          </div>
        </div>
      </a>
    </rx-view>
  </plane-home>
</template>
<script>
import mixinBase from "@src/components/mixins/mixinBase";
export default {
  name: "home-tech",
  mixins: [mixinBase],
  data() {
    return {
      i: 0,
      items: [],
    };
  },
  created() {
    A.updata.getHomeReport().then((res) => {
      this.items = res.data.records;
    });
  },
  mounted() {},
  methods: {
    selectItem(val) {
      this.i = val;
    },
  },
};
</script>
<style lang="less" scoped>
.homeTech {
  position: relative;
  padding: 28px 22px;
  color: #666666;
  .techItem {
    position: relative;
    margin-bottom: 31px;
    cursor: pointer;
    h4 {
      font-size: 16px;
      margin-bottom: 10px;
    }
    .image {
      margin-right: 19px;
      line-height: 0;
      height: 125px;
      width: 100px;
      overflow: hidden;
      .itemImg {
        transition: transform 0.6s;
      }
      &:hover {
        .itemImg {
          transform: scale(1.1);
        }
      }
    }
    .des {
      margin: 20px 0;
      line-height: 24px;
    }
    .tag {
      margin-right: 8px;
      border: none;
      font-size: 12px;
    }
    .ename {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      color: #999999;
      .name {
        color: #000000;
      }
    }
  }
}
</style>