<template>
  <rx-view class="homeActivity" :loading="isLoading('getIndustryHomeList')">
    <item-activity
      class="citem"
      v-for="(item, index) in list"
      :key="index"
      :info="item"
      :type="item.watchStatus"
    ></item-activity>
  </rx-view>
</template>
<script>
import mixinBase from "@src/components/mixins/mixinBase";
export default {
  name: "home-activity",
  mixins: [mixinBase],
  data() {
    return {
      items: ["zb", "yg", "hf", "hg"],
      list: [],
    };
  },
  created() {
    A.updata.getIndustryHomeList().then((res) => {
      if (res.data) {
        if (res.data.length > 4) {
          this.list = res.data.slice(0, 4);
        } else {
          this.list = res.data;
        }
      }
    });
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.homeActivity {
  max-width: 1200px;
  padding: 28px 0;
  margin: auto;
  height: 500px;
  .citem {
    float: left;
    margin-right: 27px;
    &:last-child {
      margin-right: 0;
    }
  }
}
</style>