<template>
  <div class="p_title">
    <h2>{{h2}}</h2>
    <h4>{{h4}}</h4>
  </div>
</template>
<script>
export default {
  name: "p-title",
  props: {
    h2: {
      type: String,
      default: "",
    },
    h4: {
      type: String,
      default: "",
    },
  },
};
</script>
<style lang="less" scoped>
.p_title {
  padding: 25px;
  text-align: center;
  h2 {
    color: #000000;
    font-size: 30px;
    line-height: 1;
    padding: 9px;
  }
  h4 {
    color: #666666;
    font-size: 16px;
    line-height: 1;
    padding: 9px;
    font-weight: normal;
    letter-spacing:2px;
  }
}
</style>