// 路由相关控制
// VueRouter：vue-router对象
//_options：App配置传入
import loginPages from '../config/loginPages'
export default function (VueRouter, _options) {
    if (typeof _options.pages == 'undefined') return false;
    const routes = _options.pages;
    // 增加路由返回方法
    VueRouter.prototype.goBack = function (sub) {
        this.isBack = true
        sub = sub || -1;
        window.history.go(sub)
    }
    var base = '/'
    if (process.env.VUE_APP_PAGE != 'wap') {
        base = '/' + process.env.VUE_APP_PAGE + '/' || '/home/'
    }
    
    // 创建路由
    var _router = new VueRouter({
        mode: 'history',
        base,
        routes,
    });
    // 页面切换之前
    _router.beforeEach((to, from, next) => {
        _options.beforeEach && _options.beforeEach(to, from)
        // console.log("路由变化：", "to:" + to.name, " | from:" + from.name, _router.isBack, " |  A.os.name:" + A.os.name)
        // _page = _pnames.find(n => n == to.name)
        if (loginPages.find(n => n == to.name) && !A.db.token) {
            A.gotoLogin()
        } else {
            next();
        }
    });
    // // 页面切换之后
    _router.afterEach((to, from) => {
        if (to.meta) {
            if (to.meta.title) {
                document.title = to.meta.title || '融象产盟';
            }
        }
        _options.afterEach && _options.afterEach(to, from)

        A.store.commit('global/setPage', to)
    });
    return _router;
};