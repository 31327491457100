<template>
  <el-dialog class="vipalert" title="提示" :visible.sync="isopen" @closed="closed">
    <div slot="title" class="dialog-title">
      <h3>开通会员</h3>
      <div>{{ title }}</div>
    </div>
    <div class="content">{{ content }}</div>
    <div class="alert-button">
      <a class="button" type="primary" href="/server" @click="closed(true)" target="_blank" data-ulog>去开通</a>
    </div>
  </el-dialog>
</template>
<script>
  export default {
    name: "rx-vip-alert",
    data() {
      return {
        title: "使用更多条件组合",
        content: "",
        isopen: true,
      };
    },
    created() {},
    mounted() {},
    methods: {
      closed(val) {
        !val && cuAtion.sendClick("关闭")
        this.$destroy(true);
        this.$el.parentNode.removeChild(this.$el);
      },
    },
  };
</script>
<style lang="less" scoped>
  .vipalert {
    overflow: hidden;

    /deep/.el-dialog {
      border-radius: 7px 7px 0 0;
      width: 400px;
    }

    /deep/.el-dialog__header {
      padding: 0;
    }

    /deep/.el-dialog__body {
      padding: 30px;
    }

    /deep/.el-input__inner {
      border-radius: 0;
    }

    .dialog-title {
      background-image: url("../../assets/img/vipalert.png");
      background-size: 100%;
      height: 116px;
      padding-left: 155px;
      padding-top: 30px;
      color: #ffffff;
      font-size: 16px;

      h3 {
        font-size: 20px;
        color: #ffffff;
      }
    }

    .content {
      padding: 20px 0 50px 0;
      font-size: 16px;
    }

    .alert-button {
      .button {
        display: inline-block;
        width: 100%;
        height: 40px;
        background: #2659ed;
        font-weight: bold;
        font-size: 16px;
        color: #ffffff;
        text-align: center;
        line-height: 40px;

        &:hover {
          background: #446eec;
        }
      }
    }
  }
</style>