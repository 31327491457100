<template>
  <div class="vx-flex user" :class="{exp:expertInfo.expertLabel}">
    <div>
      <rx-avatar width="76px" height="76px" :src="info.userLogo"></rx-avatar>
    </div>
    <div class="vx-flex_item islogin" v-if="token">
      <div class="wcmtxt vx-flex">
        <div class="ellipsis">{{ info.userName }}</div>
        <div class="vx-flex_item"><rx-utag :label="expertInfo.expertLabel" v-if="expertInfo.expertLabel"></rx-utag></div>        
      </div>
      <a href="/ucenter" class="enuc" data-ulog="banner-用户中心">用户中心 <i class="el-icon-arrow-right"></i></a>
    </div>
    <div class="vx-flex_item" v-else>
      <div class="wcmtxt">欢迎来到融象产盟</div>
      <div class="loginbtn">
        <a href="/user/login" data-ulog="banner-登录">登录</a>
        <a href="/user/register" data-ulog="banner-注册">注册</a>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "home-login-user",
  computed: {
    ...mapState("user", {
      info: (state) => state.info,
      token: (state) => state.token,
      expertInfo: (state) => state.expertInfo,
    }),
    ...mapState("global", {
      utag: (state) => state.utag,
    }),
  },
};
</script>
<style lang="less" scoped>
.user {
  padding: 23px;
  height: 125px;
  &.exp{
    padding-bottom: 18px;
  }
  .wcmtxt {
    font-size: 16px;
    color: #000000;
    line-height: 2;
    margin-bottom: 5px;
  }
  .enuc {
    font-size: 16px;
    text-align: left;
    color: #2659ed;
  }
  .islogin {
    padding-left: 30px;
    text-align: left;
    .wcmtxt {
      font-size: 20px;
      color: #111111;
      font-weight: bold;
      vertical-align: middle;
      .utag {
        text-align: center;
      }
    }
    .ellipsis{
      padding-right:10px;
    }
  }
  .loginbtn {
    width: 128px;
    height: 27px;
    display: inline-block;
    background-image: url("../assets/btnLogin.png");
    background-repeat: no-repeat;
    color: #4b6ff7;
    a {
      width: 50%;
      display: inline-block;
      line-height: 27px;
    }
    a:first-child {
      color: #ffffff;
    }
  }
}
</style>