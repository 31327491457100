import vwx from 'vwx'
import topNav from './topNav'
import pages from './pages'
import store from './data/store'
import coms from './components'
import './assets/home.less'

vwx.createApp({
  pages,
  store,
  onLaunch() {
    A.winScrollListener();
  },
  onShow(app, page) {},
  beforeEach(to, from) {
    A.catchUaction.changPage(to, from);
  }
}, coms)

topNav["store"] = A.store;
new vwx.Vue(topNav);