<template>
  <div class="homeBanner">
    <el-carousel :autoplay="true" trigger="click" height="450px">
      <el-carousel-item v-for="item in items" :key="item.itemId">
        <a
          class="bnimg"
          :style="{ backgroundImage: 'url(' + item.itemFile + ')' }"
          :href="item.itemUrl"
          target="_blank"
          v-if="item.itemUrl"
          :data-ulog="'banner::'+item.itemUrl"
        ></a>
        <a
          class="bnimg"
          :style="{ backgroundImage: 'url(' + item.itemFile + ')' }"
          v-else
        ></a>
      </el-carousel-item>
    </el-carousel>
    <div class="bannerLayer">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import mixinBase from "@src/components/mixins/mixinBase";
export default {
  name: "home-banner",
  mixins: [mixinBase],
  data() {
    return {
      items: [],
    };
  },
  created() {
    A.updata
      .getPageItem({
        areaCode: "pc.index.banner",
        limit: 6,
      })
      .then((res) => {
        this.items = res.data;
      });
  },
};
</script>
<style lang="less" scoped>
.homeBanner {
  height: 450px;
  background-color: #041958;
  position: relative;
  .bnimg {
    display: block;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .bannerLayer {
    position: absolute;
    width: 1200px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    .ulogin {
      position: absolute;
      top: 27px;
      right: 0;
    }
  }
}
</style>