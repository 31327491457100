<template>
  <div id="home">
    <home-menu></home-menu>
    <!-- banner内容 -->
    <home-banner>
      <home-login class="ulogin" @click-publish="publish"></home-login>
    </home-banner>
    <!-- <p-title h2="产业服务" h4="一站式产业服务 满足企业全方位需求"></p-title>
    <home-plane-sever></home-plane-sever> -->
    <div class="hmdemand">
      <p-title h2="需求市场" h4="500+位产业专家 专业对接您的需求"></p-title>
      <home-demand @click-publish="publish"></home-demand>
    </div>
    <p-title h2="优质采购商" h4="50w+企业资源 高效促成市场合作"></p-title>
    <home-company></home-company>
    <p-title h2="成功案例" h4="优选标杆案例 尽享产业服务"></p-title>
    <home-case></home-case>
    <div class="activity">
      <div class="pallet"></div>
      <p-title
        h2="产业对接活动"
        h4="专业的产业培训和产业精准对接活动"
      ></p-title>
      <home-activity></home-activity>
    </div>
    <div class="vx-flex qazx">
      <div class="vx-flex_item left">
        <home-qa></home-qa>
      </div>
      <div class="vx-flex_item right">
        <home-consult></home-consult>
      </div>
    </div>
    <div class="vx-flex newsyb">
      <div class="vx-flex_item left">
        <home-news></home-news>
      </div>
      <div class="right">
        <home-tech></home-tech>
      </div>
    </div>
    <HomePublish ref="publish"></HomePublish>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Page from "vwx/page";
import HomeMenu from "./homeMenu";
import HomeBanner from "./homeBanner";
import HomeLogin from "./homeLogin";
import HomePlaneSever from "./homePlaneSever";
import HomeDemand from "./homeDemand";
import HomeCompany from "./homeCompany";
import HomeActivity from "./homeActivity";
import HomeCase from "./homeCase";
import HomeQa from "./homeQa";
import HomeConsult from "./homeConsult";
import HomeNews from "./homeNews";
import HomeTech from "./homeTech";
import HomePublish from "./homePublish";

export default Page({
  name: "home",
  components: {
    HomeMenu,
    HomeBanner,
    HomeLogin,
    HomePlaneSever,
    HomeDemand: HomeDemand,
    HomeCompany,
    HomeActivity,
    HomeCase,
    HomeQa,
    HomeConsult,
    HomeNews,
    HomeTech,
    HomePublish,
  },
  data: {
    loading: false,
    muIndex: 0,
    nav: ["产业资源库", "企业库", "企业主页"],
  },
  created() {
    window.publish = (val, title) => {
      this.publish(val, title);
    };
  },
  methods: {
    publish(val, title) {
      this.$refs.publish.open(val, title);
    },
    loadData() {
      A.confirm("是否加载数据", (res) => {
        if (res == "confirm") {
          A.updata
            .getPageItem({ areaCode: "page.expert.nav", limit: 3 })
            .then((res) => {
              console.log(res);
            });
        }
      });
    },
  },
});
</script>

<style lang="less">
#home {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  .neirong {
    background-color: #1d3f98;
  }
  /deep/.el-carousel__button {
    width: 10px;
    height: 10px;
    background: #ffffff;
    opacity: 0.5;
    border-radius: 50%;
  }
  /deep/.el-carousel__indicator.is-active button {
    opacity: 1;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5);
  }
  .hmdemand {
    text-align: center;
    // background-color: #f1f3f7;
    padding: 10px 0 30px 0;
    // background: linear-gradient(180deg, #ffffff 0%, #f1f3f7 80%, #f1f3f7 100%);
  }
  .activity {
    position: relative;
    margin-top: 20px;
    min-height: 640px;
    background-image: url(../assets/hbg.jpg);
    background-repeat: no-repeat;
    background-position: center;
    .pallet {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      height: 113px;
      background-image: url(../assets/sc30.png);
      background-repeat: no-repeat;
      background-position: bottom center;
    }
  }
  .qazx {
    max-width: 1200px;
    margin: 30px auto;
    margin-top: 0;
    .left {
      padding-right: 10px;
    }
    .right {
      padding-left: 10px;
    }
  }
  .newsyb {
    max-width: 1200px;
    margin: 50px auto;
    .left {
      padding-right: 20px;
    }
    .right {
      width: 390px;
    }
  }
}
</style>
