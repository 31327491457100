<template>
  <rx-view class="demand" :class="[type]" :loading="isLoading(query[type])">
    <div class="demandTop">
      <div>
        <span style="font-size: 22px">{{
          type == "sale" ? "8000+" : "2000+"
        }}</span>
        {{
          type == "sale" ? "供应商选择我们为其对接" : "采购商选择我们为其促成"
        }}
      </div>
      <div class="h02">{{ market[type].title }}</div>
      <img :src="market[type].img" />
    </div>
    <div class="dmList">
      <div
        class="items"
        :class="{ stop: stop }"
        :style="{ transform: 'translateY(' + pi * -160 + 'px)' }"
      >
        <a
          class="vx-flex dmItem hover"
          v-for="(v, i) in items"
          :key="i"
          :href="href(v)"
          target="_blank"
          data-ulog="需求市场-查看需求"
        >
          <div class="vx-flex_item">{{ v.demandTitle }}</div>
          <div class="price">
            {{ unit(v) }}
          </div>
        </a>
      </div>
    </div>
    <el-button
      type="primary"
      class="btn01"
      @click="clickBtn(market[type].title)"
      >发布{{ market[type].title }}</el-button
    >
  </rx-view>
</template>
<script>
import { mapState } from "vuex";
import mixinBase from "@src/components/mixins/mixinBase";
export default {
  name: "item-demand",
  mixins: [mixinBase],
  props: {
    type: {
      type: String,
      default: "sale",
    },
  },
  computed: {
    ...mapState("user", {
      expertInfo: (state) => state.expertInfo,
    }),
  },
  data() {
    return {
      items: [],
      query: {
        sale: "getLatestSale",
        buy: "getLatestPurchase",
      },
      market: {
        sale: {
          img: require("../assets/sc10.png"),
          title: "销售需求",
        },
        buy: {
          img: require("../assets/sc12.png"),
          title: "采购需求",
        },
        finance: {
          img: require("../assets/sc11.png"),
          title: "融资需求",
        },
      },
      pi: 0,
      pSize: 2,
      stop: false,
      ps: -1,
    };
  },
  mounted() {
    if (this.type == "sale") {
      A.updata.getLatestSale().then((res) => {
        this.pushData(res.data);
      });
    } else {
      A.updata.getLatestPurchase().then((res) => {
        this.pushData(res.data);
      });
    }
  },
  methods: {
    pushData(data) {
      if (data.length > 5) {
        this.ps = data.length % 5;
        this.pSize = Math.floor(data.length / 5);
        if (this.ps == 0) {
          let push = data.slice(0, 5);
          this.items = [...data, ...push];
        } else {
          this.items = data;
        }
        this.play();
      } else {
        this.items = data;
      }
    },
    clickBtn(val) {
      cuAtion.sendClick('发布'+val)
      if (this.expertInfo.expertId) {
        A.confirm(
          "您为平台行家用户，暂不支持【" + val + "】",
          () => {},
          "",
          "关闭",
          "知道了"
        );
      } else {
        this.$emit("click-putbtn", val);
      }
    },
    href(v) {
      if (this.type == "sale") {
        return "/resources/selling?id=" + v.demandId;
      } else {
        return "/resources/purchase?id=" + v.demandId;
      }
    },
    unit(v) {
      if (v.quotedPrice) {
        if (v.quotedPrice == "面议") {
          return "面议";
        } else {
          return v.quotedPrice + v.saleUnit;
        }
      } else if (v.purchaseAmount) {
        return v.purchaseAmount + v.purchaseUnit;
      } else {
        return this.type == "sale" ? "面议" : "-";
      }
    },
    play() {
      setInterval(() => {
        if (this.pi <= this.pSize) {
          this.stop = false;
          this.pi++;
          if (this.pi == this.pSize && this.ps == 0) {
            setTimeout(() => {
              this.stop = true;
              this.pi = 0;
            }, 2000);
          } else if (this.pi == this.pSize) {
            this.pi = 0;
          }
        }
      }, 3000);
    },
  },
};
</script>
<style lang="less" scoped>
.demand {
  position: relative;
  width: 100%;
  height: 400px;
  padding: 26px;
  background: #ffffff;
  box-shadow: 0px 6px 36px 0px rgba(0, 0, 0, 0.08);
  border-radius: 6px 6px 0px 0px;
  overflow: hidden;
  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 6px;
    background: linear-gradient(0deg, #2659ed 0%, #577aff 100%);
    overflow: hidden;
  }
  &.buy {
    &::after {
      background: linear-gradient(90deg, #facc22 0%, #e4970b 100%);
    }
    .btn01 {
      box-shadow: 0 10px 20px 0 rgba(99, 99, 99, 0.2);
      background: linear-gradient(90deg, #facc22 0%, #e4970b 100%);
      border: none;
      &:hover {
        background: linear-gradient(90deg, #e4970b 0%, #e4970b 100%);
      }
    }
  }
  .demandTop {
    position: relative;
    height: 80px;
    padding: 0;
    text-align: left;
    img {
      position: absolute;
      top: 5px;
      right: 5px;
    }
  }
  .dmList {
    height: 160px;
    margin: 28px 0;
    padding: 0;
    font-size: 14px;
    text-align: left;
    overflow: hidden;
    .items {
      position: relative;
      transition: transform 1s;
      transform: translateY(0);
      &.stop {
        transition-duration: 0s;
      }
    }
    .dmItem {
      line-height: 32px;
      height: 32px;
      .vx-flex_item {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }
      .price {
        width: 80px;
        margin-left: 50px;
        color: #ff7a00;
        text-align: left;
      }
    }
  }
}
</style>