<template>
  <div class="rxView">
    <slot></slot>
    <div class="loading" v-if="loading">
      <div class="loadEffect" :class="[direction]">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>

  </div>
</template>
<script>
  export default {
    name: "rx-view",
    props: {
      loading: {
        type: Boolean,
        default: false,
      },
      direction: {
        type: String,
        default: "center",
      },
    },
  };
</script>
<style lang="less" scoped>
  .rxView {
    position: relative;

    .loading {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.9);
      z-index: 110;

      .el-icon-loading {
        font-size: 20px;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #111111;

        &.top {
          top: 10px;
        }

        &.bottom {
          bottom: 10px;
        }

        &.center {
          top: 50%;
        }
      }
    }

    .loadEffect {
      display: inline-block;
      width: 40px;
      height: 40px;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);

      &.top {
        top: 40px;
      }

      &.bottom {
        bottom: 40px;
      }

      &.center {
        top: 50%;
      }
    }

    .loadEffect span {
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #0095FF;
      position: absolute;
      animation: load 1.04s ease infinite;
      transform: scale(.4);
    }

    @keyframes load {
      0% {
        transform: scale(.4);
        opacity: .5;
      }

      50% {
        transform: scale(1);
        opacity: 1;
      }

      100% {
        transform: scale(.4);
        opacity: .5;
      }
    }

    .loadEffect span:nth-child(1) {
      left: 0;
      top: 50%;
      margin-top: -4px;
      animation-delay: 0.13s;
    }

    .loadEffect span:nth-child(2) {
      left: 5px;
      top: 5px;
      animation-delay: 0.26s;
    }

    .loadEffect span:nth-child(3) {
      left: 50%;
      top: 0;
      margin-left: -4px;
      animation-delay: 0.39s;
    }

    .loadEffect span:nth-child(4) {
      top: 5px;
      right: 5px;
      animation-delay: 0.52s;
    }

    .loadEffect span:nth-child(5) {
      right: 0;
      top: 50%;
      margin-top: -4px;
      animation-delay: 0.65s;
    }

    .loadEffect span:nth-child(6) {
      right: 5px;
      bottom: 5px;
      animation-delay: 0.78s;
    }

    .loadEffect span:nth-child(7) {
      bottom: 0;
      left: 50%;
      margin-left: -4px;
      animation-delay: 0.91s;
    }

    .loadEffect span:nth-child(8) {
      bottom: 5px;
      left: 5px;
      animation-delay: 1.04s;
    }
  }
</style>