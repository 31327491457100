<template>
  <div class="planes">
    <div class="plane">
      <div class="items" v-if="tab == 'sale'">
        <a href="/server/help?id=1" target="_blank" class="item hover" data-ulog="banner-如何寻找销售渠道"><img
            src="../assets/h01.png" /> 如何寻找销售渠道？
          <span>了解详情 <i class="el-icon-arrow-right"></i></span></a>
        <a href="/server/help?id=3" target="_blank" class="item hover" data-ulog="banner-无法触达对接企业"><img
            src="../assets/h02.png" /> 无法触达对接企业？<span>解决方案 <i class="el-icon-arrow-right"></i></span></a>
        <a href="/server/help?id=4" target="_blank" class="item hover" data-ulog="banner-快速了解玩转产盟"><img
            src="../assets/h03.png" /> 快速了解 玩转产盟 →
          <span>新人引导 <i class="el-icon-arrow-right"></i></span></a>
      </div>
      <div class="items" v-else>
        <a href="/server/help?id=2" target="_blank" class="item hover" data-ulog="banner-如何寻找供货来源"><img
            src="../assets/h01.png" /> 如何寻找供货来源？
          <span>了解详情 <i class="el-icon-arrow-right"></i></span></a>
        <a href="/server/help?id=3" target="_blank" class="item hover" data-ulog="banner-无法触达对接企业"><img
            src="../assets/h02.png" /> 无法触达对接企业？<span>解决方案 <i class="el-icon-arrow-right"></i></span></a>
        <a href="/server/help?id=4" target="_blank" class="item hover" data-ulog="banner-快速了解玩转产盟"><img
            src="../assets/h03.png" /> 快速了解 玩转产盟 →
          <span>新人引导 <i class="el-icon-arrow-right"></i></span></a>
      </div>
      <el-button type="primary" class="btn01" @click="clickBtn">
        {{ tab == "sale" ? "发布销售需求" : "发布采购需求" }}
      </el-button>
    </div>
  </div>
</template>
<script>
  export default {
    name: "login-con-out",
    props: {
      tab: {
        type: String,
        default: "sale",
      },
    },
    computed: {
      tabName() {
        return this.tab == "sale" ? "发布销售需求" : "发布采购需求"
      }
    },
    methods: {
      clickBtn() {
        cuAtion.sendClick(this.tabName);
        this.$emit('click-btn')
      }
    }
  };
</script>
<style lang="less" scoped>
  .planes {
    height: 203px;
    background-color: #fafcff;
    padding: 2px 25px;

    .items {
      margin: 10px 0 30px 0;
    }

    .item {
      display: block;
      text-align: left;
      height: 30px;
      line-height: 30px;
      font-size: 14px;
      vertical-align: middle;

      img {
        position: relative;
        margin-right: 3px;
        top: 1px;
      }

      span {
        float: right;
        color: #f3a600;
      }
    }
  }
</style>