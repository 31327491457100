<template>
  <rx-view class="homeCase vx-flex" :loading="isLoading('getCase')">
    <div class="caseExplain" @mouseover="changePlay(false)" @mouseout="changePlay(true)">
      <div class="h02 ellipsis d-mid">
        <img class="case-logo" :src="info.companyLogo" alt="">
        <span>{{ info.caseTitle }}</span>
      </div>
      <div class="vx-flex exSm">
        <div class="vx-flex_item">
          <h4 class="h04">所属行业</h4>
          <h3 class="h03 ellipsis">{{ info.industry }}</h3>
        </div>
        <div class="vx-flex_item">
          <h4 class="h04">对接方</h4>
          <h3 class="h03 ellipsis">{{ info.recipient }}</h3>
        </div>
      </div>
      <div class="exContent">
        <pre>{{ info.caseDetail }}</pre>
      </div>
    </div>
    <div class="vx-flex_item">
      <div class="caseList">
        <div class="caseItems">
          <div
            class="caseItem"
            :class="{ act: index == i }"
            v-for="(val, index) in items"
            :key="index"
            @mouseover="changeIndex(index, val)"
            @mouseout="changePlay(true)"
          >
            <img v-if="index == i" class="bg-border" src="../assets/h05.png" alt="">
            <span
              class="img"
              :style="{ backgroundImage: 'url(' + val.picList[0] + ')' }"
            >
            </span>
          </div>
        </div>
      </div>
    </div>
  </rx-view>
</template>
<script>
import mixinBase from "@src/components/mixins/mixinBase";
export default {
  name: "home-case",
  mixins: [mixinBase],
  data() {
    return {
      i: 4,
      items: [],
      info: {},
    };
  },
  created() {
    A.updata.getCase(9).then((res) => {
      this.items = res.data;
      this.info = res.data[0];
      this.changePlay(true);
    });
  },
  mounted() {},
  methods: {
    changeIndex(i, val) {
      this.i = i;
      this.info = val;
      this.changePlay(false);
    },
    changePlay(play = true) {
      if (this.it) {
        clearInterval(this.it);
        this.it = null;
      }
      if (play) {
        this.it = setInterval(() => {
          if (this.i < this.items.length-1) {
            this.i++;
          } else {
            this.i = 0;
          }
          this.info = this.items[this.i];
        }, 3000);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.homeCase {
  max-width: 1200px;
  margin: auto;
  height: 492px;
  .caseExplain {
    width: 572px;
    height: 492px;
    padding: 42px;
    background: linear-gradient(0deg, #2659ed 0%, #577aff 100%);
    border-radius: 20px 0px 0px 20px;
    color: #ffffff;
    box-shadow: 0px 10px 24px 0px rgba(0, 0, 0, 0.12);
    .h03,
    .h04,
    .h02 {
      color: #ffffff;
    }
    .h03{
      width: 80%;
    }
    .case-logo {
      width: 75px;
      height: 75px;
      border-radius: 50%;
      vertical-align: middle;
      margin-right: 15px;
    }
    .d-mid {
      vertical-align: middle;
      font-size: 20px;
    }
    .exSm {
      height: 53px;
      > div:first-child {
        position: relative;
        &::after {
          content: "";
          position: absolute;
          top: 0;
          right: 30px;
          height: 100%;
          width: 1px;
          border-right: 1px rgba(238, 238, 238, 0.3) solid;
        }
      }
      margin: 18px 0 30px;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        bottom: -15px;
        height: 1px;
        width: 100%;
        background-color: rgba(238, 238, 238, 0.3);
      }
    }
    .exContent {
      margin-top: 50px;
      font-size: 14px;
      line-height: 24px;
      height: 190px;
      // overflow: hidden;
      // text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      // -webkit-line-clamp: 8;
      overscroll-behavior: contain;
      overflow: auto;
      pre {
        white-space: pre-wrap;
        *word-wrap: break-word;
        *white-space: normal;
      }
    }
  }
  .caseList {
    margin-top: 22px;
    height: 448px;
    width: 100%;
    padding: 19px 15px;
    box-shadow: 0px 10px 24px 0px rgba(0, 0, 0, 0.12);
    .caseItems {
      // border-top: 1px solid #eeeeee;
      // border-left: 1px solid #eeeeee;
      height: 420px;
    }
    .caseItem {
      position: relative;
      width: 190px;
      height: 118px;
      float: left;
      display: block;
      margin-bottom: 10px;
      // border-right: 1px solid #eeeeee;
      // border-bottom: 1px solid #eeeeee;
      transition: box-shadow 0.6s;
      // padding: 10px;
      &:nth-child(3n+1), &:nth-child(3n+2) {
        margin-right: 10px;
      }
      &::before {
        position: absolute;
        content: "";
        height: 0;
        width: 0;
        bottom: 0px;
        left: 0px;
        z-index: 2;
        border: 5px solid transparent;
        border-bottom: 5px #ffffff solid;
        border-left: 5px #ffffff solid;
      }
      .img {
        background-size: 100%;
        background-repeat: no-repeat;
        display: block;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
      .bg-border {
        width: 204px;
        height: 132px;
        position: absolute;
        z-index: 9;
        top: -7px;
        left: -7px;
      }
      &.act {
        z-index: 1;
        &::before {
          border: 10px solid transparent;
          border-bottom: 10px #ffffff solid;
          border-left: 10px #ffffff solid;
        }
        // &::after {
        //   position: absolute;
        //   height: 0;
        //   width: 0;
        //   top: 5px;
        //   left: 5px;
        //   z-index: 2;
        //   border: 15px solid transparent;
        //   border-top: 15px #2659ed solid;
        //   border-left: 15px #2659ed solid;
        //   content: "";
        // }
      }
    }
  }
}
/*滚动条宽高*/
.exContent::-webkit-scrollbar{
  width: 5px;
  height: 22px;
}
/*正常情况下滑块的样式*/
.exContent::-webkit-scrollbar-thumb{
  border-radius: 10px;
  width: 3px;
  background: linear-gradient(#8AA8FF, #FFFFFF);
}
/*正常时候的主干部分*/
.exContent::-webkit-scrollbar-track{
  border-radius: 10px;
  // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: #3960DD;
}

/*鼠标悬浮在滚动条上的主干部分*/
.exContent::-webkit-scrollbar-track:hover{
  // box-shadow: inset 0 0 6px rgba(0, 0, 0, .4);
  background-color: #3960DD;
}
</style>