<template>
  <a
    class="iactivity hoverMoveUp"
    :href="'/abutment/live?liveId=' + info.liveId"
    target="_blank"
    data-ulog="查看直播"
  >
    <div
      class="actImg"
      :style="{ backgroundImage: 'url(' + info.liveLogo + ')' }"
    ></div>
    <div class="actContent">
      <div class="title ellipsis hover">{{ info.liveName }}</div>
      <div class="master">主讲人：{{ info.speaker }}</div>
      <div class="more">
        <template v-if="info.watchStatus == 'live'"
          >直播进行中，赶紧来围观~</template
        >
        <template v-if="info.watchStatus == 'waiting'">{{
          with24h(info.startTime) ? "开播倒计时：" + timeinfo : info.startTime
        }}</template>
        <template v-if="info.watchStatus == 'playback'"
          >直播时间：{{ info.startTime }}</template
        >
      </div>
      <div class="status">
        <span class="stTxt">{{ price(info) }}</span>
        {{ num(info) }}
        <span class="btn">{{ btn[info.watchStatus] }}</span>
      </div>
    </div>
    <div class="rxcm_icon_tagbg2 tagTop" :class="[info.watchStatus]">
      <span>{{ tag[info.watchStatus] }}</span>
    </div>
  </a>
</template>
<script>
import moment from "moment";
export default {
  name: "item-activity",
  props: {
    type: {
      type: String,
      default: "live",
    },
    info: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      timeinfo: "",
      tag: {
        live: "正在直播",
        waiting: "直播预告",
        playback: "直播回放",
        hg: "会议回顾",
      },
      des: {
        live: "直播进行中，赶紧来围观~",
        waiting: "预约",
        playback: "看回放",
      },
      btn: {
        live: "看直播",
        waiting: "预约",
        playback: "看回放",
        hg: "了解详情",
      },
    };
  },
  mounted() {
    if (this.with24h(this.info.startTime)) {
      this.it = setInterval(this.countTime.bind(this), 1000);
    }
  },
  deactivated() {
    clearInterval(this.it);
  },
  methods: {
    price(val) {
      if (val.liveIsFree) {
        return "免费";
      } else if (val.livePromotionPrice) {
        return "￥" + Math.round(val.livePromotionPrice/100);
      } else if (val.liveVipPrice) {
        return "￥" +  Math.round(val.livePrice/100);
      } else {
        return "￥" +  Math.round(val.livePrice/100);
      }
    },
    num(val) {
      if (val.watchStatus == "live") {
        return val.viewers + "人正在看";
      } else if (val.watchStatus == "waiting") {
        return val.viewers + "人已预约";
      } else {
        return val.viewers + "人观看";
      }
    },
    with24h(startTime) {
      var lefttime = moment(startTime).unix() - moment().unix();
      if (lefttime < 86400 && lefttime > 0) {
        return true;
      } else {
        return false;
      }
    },
    countTime() {
      let lefttime2 =
        moment(this.info.startTime).unix() - moment(new Date()).unix();
      //定义变量 d,h,m,s保存倒计时的时间
      if (lefttime2 >= 0) {
        let h = Math.floor((lefttime2 / 60 / 60) % 24);
        let m = Math.floor((lefttime2 / 60) % 60);
        let s = Math.floor(lefttime2 % 60);
        if (parseInt(m) < 9) {
          m = "0" + m;
        }
        if (parseInt(h) < 9) {
          h = "0" + h;
        }
        if (parseInt(s) < 9) {
          s = "0" + s;
        }
        this.timeinfo = h + ":" + m + ":" + s;
      } else {
        this.timeinfo = moment(this.info.startTime).format("MM-DD hh:mm");
      }
    },
    selectItem(val) {
      this.i = val;
    },
  },
};
</script>
<style lang="less" scoped>
.iactivity {
  position: relative;
  display: inline-block;
  width: calc((100% - 81px) / 4);
  height: 380px;
  background: #ffffff;
  box-shadow: 0px 10px 36px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px 0px 0px 0px;
  overflow: hidden;
  .actImg {
    display: block;
    width: 100%;
    height: 194px;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .actContent {
    display: block;
    height: 186px;
    margin: 25px 20px;
    color: #666666;
    font-size: 14px;
    line-height: 38px;
    .title {
      color: #000000;
      font-size: 18px;
      line-height: 1.5;
    }
    .status {
      position: relative;
      width: 100%;
      .stTxt {
        color: #ff7a00;
        font-size: 18px;
      }
    }
    .btn {
      position: absolute;
      right: 0;
      bottom: 5px;
      height: 32px;
      line-height: 32px;
      padding: 0 16px;
      color: #1347cf;
      font-size: 16px;
      border: 1px solid #1347cf;
      border-radius: 16px;
      cursor: pointer;
      &:hover {
        background: rgba(38, 89, 237, 0.05);
      }
    }
  }
  .tagTop {
    position: absolute;
    top: 0;
    left: 10px;
    width: 97px;
    height: 28px;
    text-align: center;
    padding-left: 5px;
    font-size: 14px;
    color: #ffffff;
    span {
      display: block;
      color: #ffffff;
      z-index: 99;
      position: absolute;
      left: 5px;
      padding-top: 2px;
      width: 100%;
      text-align: center;
    }
    &:before {
      color: #2659ed;
      font-size: 28px;
      top: -8px;
      position: absolute;
      left: 0;
    }
    &.live:before {
      color: #2659ed;
    }
    &.waiting:before {
      color: #ff9000;
    }
    &.playback:before {
      color: #263471;
    }
    &.hg:before {
      color: #069ed0;
    }
  }
}
</style>