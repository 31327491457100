<template>
  <div class="desc-text">
    <div class="tag-wrap">
      <span :class="tags[i]==true?'span-active':''" v-for="(item,i) in list" :key="item" @click="changeTag(i)">{{item}}</span>
    </div>
    <div v-if="showDesc" class="form-item-input">
      <el-input class="desc-item" rows=5 type="textarea" v-model="desc" @change="getInput()"></el-input>
    </div>
    <div v-if="tags[0]" class="form-item-input">
      <label>质量：</label>
      <el-input class="desc-item" rows=5 type="textarea" v-model="desc1" @change="getInput()">
      </el-input>
    </div>    
    <div v-if="tags[1]" class="form-item-input">
      <label>价格：</label>
      <el-input class="desc-item" rows=5 type="textarea" v-model="desc2" @change="getInput()">
      </el-input>
    </div>
    <div v-if="tags[2]" class="form-item-input">
      <label>品牌：</label>
      <el-input class="desc-item" rows=5 type="textarea" v-model="desc3" @change="getInput()">
      </el-input>
    </div>
    <div v-if="tags[3]" class="form-item-input">
      <label>服务：</label>
      <el-input class="desc-item" rows=5 type="textarea" v-model="desc4" @change="getInput()">
      </el-input>
    </div>
    <div v-if="tags[4]" class="form-item-input">
      <label>技术：</label>
      <el-input class="desc-item" rows=5 type="textarea" v-model="desc5" @change="getInput()">
      </el-input>
    </div>
  </div>
</template>

<script>
export default {
  name: "appraiseItem",
  props: ['info'],
  data() {
    return {
      list: ["质量","价格","品牌","服务","技术"],
      desc: "",
      desc1: "",
      desc2: "",
      desc3: "",
      desc4: "",
      desc5: "",
      tags: { 0: false, 1: false, 2: false, 3: false, 4: false},
    }
  },
  computed: {
    showDesc() {
      if (this.tags[0]==false&&this.tags[1]==false&&this.tags[2]==false&&this.tags[3]==false&&this.tags[4]==false) {
        return true;
      } else return false;
    }
  },
  created() {
    if (this.info) {
      this.desc = this.info.desc;
      this.desc1 = this.info.desc1;
      this.desc2 = this.info.desc2;
      this.desc3 = this.info.desc3;
      this.desc4 = this.info.desc4;
      this.desc5 = this.info.desc5;
      for (let i=0; i<5; i++) {
        let j = i+1;
        if (this.info['desc'+j]) {
          this.$set(this.tags, i, true);
        }
      }
    }
  },
  methods: {
    changeTag(i) {
      this.tags[i] = !this.tags[i];
      let superiority = this.getInput();
    },
    getInput() {
      let superiority = [];
      if (this.showDesc) {   
        superiority.push({
          prodSuperiorityType: 0,
          prodSuperiorityDesc: this.desc
        });
      } else {
        for(let key in this.tags) {
          if (this.tags[key] === true) {
            let index = Number(key)+1;
            superiority.push({
              prodSuperiorityType: index,
              prodSuperiorityDesc: this['desc'+index]
            })
          }
        }
      }
      this.$emit('changeDesc',superiority);
    }
  }
}
</script>

<style lang="less" scoped>
.desc-text {
  border: 1px solid #EAEAEA;
  padding: 20px;
  min-height: 185px;
  /deep/ textarea {
    resize: none;
    border: none;
    color: #000000;
  }
  .tag-wrap {
    display: flex;
    padding-bottom: 15px;
    span {
      padding: 6px 8px;
      border: 1px solid #999999;
      color: #999999;
      font-size: 12px;
      margin-right: 12px;
      line-height: 1;
      cursor: pointer;
    }
    .span-active {
      color: #FFB027;
      border-color: #FFB027;
      background: #FFFAF1;
    }
  }
  .form-item-input {
    display: flex;
    // align-items: center;
    label {
      font-size: 14px;
      color: #999999;
      white-space: nowrap;
      line-height: 22px;
    }
    .desc-item {
      font-size: 14px;
    }
    /deep/.el-textarea__inner{
      padding: 0;
    }
  }
}
</style>