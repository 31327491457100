<template>
  <el-form :model="form" :rules="rules" ref="form" label-width="90px">
    <el-form-item label="联系电话" prop="mobile" class="telitem">
      <div v-if="token">
        <el-input
          v-model="form.mobile"
          :placeholder="
            utype == 'sale'
              ? '填写手机号，以便采购商与您取得联系'
              : '填写手机号，以便供应商与您取得联系'
          "
          :disabled="true"
        ></el-input>
      </div>
      <div class="vx-flex" v-else>
        <div class="vx-flex_item">
          <el-input
            v-model="form.mobile"
            :placeholder="
              utype == 'sale'
                ? '填写手机号，以便采购商与您取得联系'
                : '填写手机号，以便供应商与您取得联系'
            "
            :maxlength="11"
          ></el-input>
        </div>
        <div class="inpuvad">
          <el-input
            v-model="form.code"
            :maxlength="6"
            placeholder="填写验证码"
          ></el-input>
        </div>
        <div class="getvad">
          <MobileCode
            class="code-box"
            ref="mobileCode"
            @sendCode="getCaptcha"
          ></MobileCode>
        </div>
      </div>
    </el-form-item>
    <el-form-item class="telitem">
      <div class="tip">
        {{
          token
            ? "您的隐私处于保护中…"
            : form.registerMark
            ? "该手机号已注册融象产盟账号，融象产盟依法保护您的隐私权益"
            : "未注册手机号登录成功将同步创建账号，融象产盟依法保护您的隐私权益"
        }}
      </div>
      <div v-if="!token">
        <span v-if="form.registerMark">您已同意融象产盟</span>
        <el-checkbox
          label="我已阅读并同意融象产盟"
          name="type"
          v-model="form.checked"
          v-else
        ></el-checkbox>
        <a target="_blank" href="/server/copyright/1"> 《服务协议》</a> <a target="_blank" href="/server/copyright/2">《隐私保护政策》</a>
        <div class="el-form-item__error xyerr" :class="{ show: showxy }">
          您需要同意协议后才能发布
        </div>
      </div>
    </el-form-item>
  </el-form>
</template>
<script>
import { mapState } from "vuex";
import MobileCode from "@/components/user/mobileCode";
var checkPhone = (rule, value, callback) => {
  if (!value) {
    callback(new Error("请填写联系电话"));
  } else {
    if (!A.reg._cellPhone(value)) {
      callback(new Error("请输入正确的手机号"));
    } else {
      callback();
    }
  }
};
export default {
  name: "publish-phone",
  components: { MobileCode },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    utype: {
      type: String,
      default: "sale",
    },
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    ...mapState("user", {
      info: (state) => state.info,
      token: (state) => state.token,
    }),
  },
  data() {
    return {
      form: {
        registerMark: false,
        checked: true,
        mobile: "",
        code: "",
        type: "Login",
      },
      rules: {
        mobile: [
          {
            validator: checkPhone,
            required: true,
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "填写验证码",
            trigger: "blur",
          },
        ],
      },
      showxy: false,
    };
  },
  watch: {
    form: {
      handler(a) {
        this.$emit("change", a);
      },
      deep: true,
    },
  },
  created() {
    if (this.utype == 'buy') {
      this.form.type = 'DemandPurchasePublish';
    } else {
      this.form.type = 'DemandSalePublish';
    }
    Object.assign(this.form, this.value);
    if (this.token) {
      this.form.mobile = this.info.userMobile.replace(
        /(\d{3})\d{4}(\d{4})/,
        "$1****$2"
      );
    }
  },
  mounted() {
    A.yidun.self = this;
    A.yidun.initNECaptcha(this.form);
  },
  methods: {
    valid(_callback) {
      if (!this.token) {
        this.$refs.form.validate((valid) => {
          if (!this.form.checked) {
            this.showxy = true;
            _callback && _callback(false);
          } else {
            this.showxy = false;
            _callback && _callback(valid);
          }
          return valid;
        });
      } else {
        this.showxy = false;
        _callback && _callback(true);
      }
    },
    getCaptcha() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (/^[1][0-9]{10}$/.test(this.form.mobile)) {
            A.yidun.mobileIns = this.$refs.mobileCode;
            A.yidun.captchaIns && A.yidun.captchaIns.verify();
          } else {
            A.message("请填写正确手机号");
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.inpuvad {
  width: 200px;
  margin: 0 10px;
}
.getvad {
  /deep/.row-code {
    width: 120px;
    height: 40px;
    line-height: 40px;
    border: 1px solid #2659ed;
    color: #2659ed;
    text-align: center;
    &:hover {
      background-color: #dfe5fa;
    }
  }
}
button {
  font-size: 16px;
  width: 100%;
}
.tip {
  color: #609a02;
}
.telitem {
  margin-bottom: 10px;
  text-align: left;
}
.xyerr {
  top: 88%;
  display: none;
  &.show {
    display: block;
  }
}
</style>