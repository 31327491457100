import Vue from 'vue';
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import coms from './components'
import Page from "./page"
import App from "./app"
import ElementUI from 'element-ui'
import vuePrototype from '../common/vuePrototype'
Vue.config.productionTip = false
Vue.use(ElementUI);

var components = coms;
const _app = function (_obj, _coms, _uses) {
    vuePrototype(Vue);
    if (window.rx_components) {
        // Vue.use(rx_components.emt);
        Vue.use(rx_components.coms);
    }
    Vue.use(VueRouter);
    Vue.use(Vuex);
    if (_coms instanceof Array) {
        components = components.concat(_coms);
    }
    if (_uses instanceof Array) {
        _uses.map(item => {
            Vue.use(item);
        });
    }
    components.map(component => {
        Vue.component(component.name, component);
    });
    App(Vue, VueRouter, Vuex, _obj);
};
const vwx = {
    v: '1.1',
    Vue,
    VueRouter,
    Vuex,
    App: _app,
    Page,
    createApp(_options, _coms) {
        _app(_options, _coms);
    }
}
const rx_module = {
    name: process.env.VUE_APP_PAGE,
    ctime: process.env.VUE_APP_CREATE_TIME
}
if (window) {
    window.vwx = vwx;
    window.rx_module = rx_module
}
let a = 'background: #ff5200; color: #fff; border-radius: 3px 0 0 3px;'
let b = 'background: #2659ed; color: #fff; border-radius: 0 3px 3px 0;'
console.log(`%c ${rx_module.name} upTime : %c ${rx_module.ctime} `, a, b)
export default vwx;