export default {
    namespaced: true,
    state: {
        info: {},
        expertInfo: {},
        baseInfo:{},
        token: ""
    },
    mutations: {
        setInfo(state, value) {
            state.info = value;
        },
        setExpertInfo(state, value) {
            state.expertInfo = value;
        },
        setToken(state, value) {
            state.token = value;
        },
        setBaseInfo(state, value) {
            state.baseInfo = value;
        },
        clear(state,val) {
            state.info= {};
            state.expertInfo= {};
            state.baseInfo = {};
            state.token= '';
        },
    }
}