<template>
  <div class="homeDemand">
    <div class="vx-flex ndPlane">
      <div class="vx-flex_item">
        <item-demand
          type="sale"
          @click-putbtn="$emit('click-publish', 'sale')"
        ></item-demand>
      </div>
      <div class="vx-flex_item">
        <item-demand
          type="buy"
          @click-putbtn="$emit('click-publish', 'buy')"
        ></item-demand>
      </div>
    </div>
    <div class="vx-flex newPlane">
      <div class="newt">
        <img src="../assets/sc13.png" />
        <span>最新需求动态</span>
      </div>
      <div class="vx-flex_item newPlaneCon">
        <div class="newlist" :class="{ gopaly: gopaly }" ref="newlist">
          <template v-for="(v, i) in dmlist">
            <span class="newi" :key="i" v-if="v.demandType == 2">
              {{ v.userName }} 发布了一条<i>采购需求</i>：{{
                v.demandTitle
              }}</span
            >
            <span class="newi" :key="i" v-else>
              {{ v.userName }} 发布了一条<b>销售需求</b>：{{
                v.demandTitle
              }}</span
            ></template
          >
        </div>
      </div>
    </div>
    <rx-view class="vx-flex" :loading="isLoading('getDemandRecommend')">
      <div class="jxban">
        <h3 class="h02">精选需求推荐</h3>
        <a href="/server" target="_blank" class="wysjx">我要上精选</a>
      </div>
      <div class="vx-flex_item jxList">
        <a
          class="jxItem hoverShadow"
          :href="href(v)"
          target="_blank"
          v-for="v in drlist"
          :key="v.demandId"
           data-ulog="精选需求-查看需求"
        >
          <div class="h03 vx-flex">
            <div class="ellipsis  hover">{{ v.demandTitle }}</div>
            <div class="vx-flex_item">
              <span class="tagTop" :class="[tagTop[v.demandType]]">{{
                ntag[v.demandType]
              }}</span>
            </div>
          </div>
          <div class="baojia">
            {{v.demandType == '1' ? '报价' : '求购'}}：<span>{{ unit(v) }}</span>
          </div>
          <div>{{ v.company }}</div>
          <div>
            <span class="label" v-if="v.categoryDesc"
              >{{ v.categoryDesc }}
            </span>
          </div>
        </a>
      </div>
    </rx-view>
  </div>
</template>
<script>
import { mapState } from "vuex";
import mixinBase from "@src/components/mixins/mixinBase";
export default {
  name: "home-demand",
  mixins: [mixinBase],
  data() {
    return {
      tab: 0,
      dmlist: [],
      drlist: [],
      gopaly: 0,
      tagTop: ["", "xs", "cg", "rz"],
    };
  },
  computed: {
    ...mapState("global", {
      ntag: (state) => state.ntag,
    }),
  },
  created() {
    A.updata.getDynamic().then((res) => {
      this.dmlist = res.data.records;
      this.$nextTick(() => {
        if (this.$refs.newlist.clientWidth > 999) {
          this.dmlist.push(...this.dmlist);
          setTimeout(() => {
            this.gopaly = true;
          }, 100);
        }
      });
    });
    A.updata.getDemandRecommend().then((res) => {
      this.drlist = res.data.slice(0, 4);
    });
  },
  mounted() {},
  methods: {
    href(v) {
      if (v.demandType == 1) {
        return "/resources/selling?id=" + v.demandId;
      } else {
        return "/resources/purchase?id=" + v.demandId;
      }
    },
    unit(v) {
      if (v.quotedPrice) {
        return v.quotedPrice + v.saleUnit;
      } else if (v.purchaseAmount) {
        return v.purchaseAmount + v.purchaseUnit;
      } else {
        return v.saleUnit;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.homeDemand {
  max-width: 1200px;
  margin: auto;
  margin-top: 10px;
  .ndPlane {
    > div:nth-child(1) {
      margin-right: 13.33px;
    }
    > div:nth-child(2) {
      margin-left: 6.66px;
      margin-right: 6.66px;
    }
    > div:nth-child(3) {
      margin-left: 13.33px;
    }
  }
  .newPlane {
    height: 72px;
    background: #ffffff;
    margin: 21px 0;
    text-align: left;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.08);
    .newPlaneCon {
      overflow: hidden;
      position: relative;
      height: 72px;
      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(
          to right,
          rgba(255, 255, 255, 1) 1%,
          rgba(255, 255, 255, 0) 5%,
          rgba(255, 255, 255, 0) 96%,
          rgba(255, 255, 255, 1) 99%
        );
      }
    }
    .newlist {
      position: absolute;
      white-space: nowrap;
      &.gopaly {
        animation: playnewlist 220s linear;
        animation-iteration-count: infinite;
      }
    }
    .newt {
      width: 201px;
      height: 72px;
      color: #2659ed;
      background-image: url("../assets/newbg.png");
      background-size: 100%;
      background-position: 0 0;
      vertical-align: middle;
      padding: 22px;
      font-size: 18px;
      font-weight: bold;
      img {
        vertical-align: middle;
        margin-right: 12px;
      }
      span {
        line-height: 1;
        vertical-align: middle;
      }
    }
    .newi {
      margin-left: 20px;
      padding-left: 20px;
      line-height: 72px;
      display: inline-block;
      background-image: url("../assets/top.png");
      background-size: 15px;
      background-position: center left;
      background-repeat: no-repeat;
      i {
        font-style: normal;
        color: #2c5def;
      }
      b {
        font-weight: normal;
        color: #14b5ad;
      }
    }
  }
  .jxban {
    margin-right: 20px;
    width: 300px;
    height: 420px;
    background-image: url(../assets/s20.png);
    padding: 42px 28px;
    box-shadow: 0px 10px 36px 0px rgba(0, 0, 0, 0.06);
    color: #ffffff;
    text-align: left;
    border-radius: 0 0 0 50px;
    .h02 {
      color: #ffffff;
    }
    .wysjx {
      display: inline-block;
      width: 100px;
      height: 31px;
      background: #f4af4b;
      color: #ffffff;
      margin-top: 10px;
      font-size: 16px;
      text-align: center;
      line-height: 31px;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border: 4px solid transparent;
        border-left: 4px solid#f4af4b;
        border-bottom: 4px solid#f4af4b;
        left: 0;
        top: -8px;
      }
      &:hover {
        background: #ff7a00;
        &::after {
          border: 4px solid transparent;
          border-left: 4px solid#ff7a00;
          border-bottom: 4px solid#ff7a00;
        }
      }
    }
  }
  .jxList {
    box-shadow: 0px 10px 36px 0px rgba(0, 0, 0, 0.06);
    background: #ffffff;
    text-align: left;
    .jxItem {
      display: block;
      float: left;
      width: 50%;
      height: 210px;
      border: #f2f3f7 1px solid;
      padding: 30px;
      color: #111111;
      &:nth-child(1) {
        border-top: none;
        border-left: none;
      }
      &:nth-child(2) {
        border-top: none;
        border-right: none;
        border-left: none;
      }
      &:nth-child(3) {
        border-top: none;
        border-left: none;
        border-bottom: none;
      }
      &:nth-child(4) {
        border: none;
      }
      > div {
        margin-bottom: 16px;
      }
    }
    .tagTop {
      display: inline-block;
      width: 76px;
      height: 21px;
      text-align: center;
      color: #ffffff;
      font-size: 12px;
      line-height: 21px;
      padding-left: 5px;
      &.xs {
        background-image: url("../assets/sc21.png");
      }
      &.cg {
        background-image: url("../assets/sc22.png");
      }
      &.rz {
        background-image: url("../assets/sc23.png");
      }
    }
    .h03 {
      font-size: 20px;
      line-height: 1.2;
    }
    .baojia {
      margin-bottom: 23px;
      span {
        font-size: 18px;
        color: #ff7a00;
        font-weight: bold;
      }
    }
    .label {
      display: inline-block;
      color: #1d44a6;
      height: 23px;
      background: #edf1f8;
      line-height: 23px;
      padding: 0 6px;
    }
  }
}
@keyframes playnewlist {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}
</style>