export default {
    namespaced: true,
    state: {
        apiLoading: false,
        apiLoads: [],
        topBorder: false,
        hideTopBar: false,
        page: {},
        utag: {
            T1001: "官方行家",
            T1002: "政府行家",
            T1003: "认证行家"
        },
        ntag: {
            '1': "销售需求",
            '2': "采购需求",
            '3': "融资需求"
        }
    },
    mutations: {
        setApiLoading(state, value) {
            if (typeof value == 'boolean') {
                state.apiLoading = value;
                if (value === false) {
                    state.apiLoads = [];
                }
            } else if (typeof value == 'object' && value instanceof Object) {
                let apiLoads = state.apiLoads;
                let index = apiLoads.findIndex(n => n == value.apiname);
                if (value.loading) {
                    state.apiLoading = true;
                    if (index < 0) {
                        apiLoads.push(value.apiname)
                    }
                } else if (index >= 0) {
                    apiLoads.splice(index, 1)
                }
                state.apiLoads = apiLoads;
                if (apiLoads.length == 0) {
                    state.apiLoading = false;
                }
            }
        },
        setTopborder(state, value) {
            state.topBorder = value;
        },
        setHideTopBar(state, value) {
            state.hideTopBar = value;
        },
        setPage(state, value) {
            state.page = value;
        }
    }
}