<template>
  <div>
    <div class="row-code" @click="sendCode">{{ countdown }}</div>
  </div>
</template>

<script>
export default {
  name: "MobileCode",
  data() {
    return {
      second: 0,
      disabled: false,
      countdown: "获取验证码",
    };
  },
  methods: {
    sendCode() {
      if (this.disabled === false) {
        this.$emit("sendCode");
      }
    },
    start() {
      if (this.disabled === false) {
        this.second = 60;
        this.timer();
      }
    },
    timer() {
      if (this.second > 0) {
        this.second--;
        this.disabled = true;
        this.countdown = this.second + "秒后重发";
        setTimeout(this.timer, 1000);
      } else {
        this.disabled = false;
        this.countdown = "获取验证码";
      }
    },
    setDisabled(val) {
      this.disabled = val;
    },
  },
};
</script>

<style lang="less" scoped>
.row-code {
  cursor: pointer;
}
</style>