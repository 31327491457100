<template>
  <div class="company vx-flex">
    <div class="planeBar">
      <img :src="comp[type].img" />
      <el-button
        class="moreBtn"
        icon="el-icon-arrow-right"
        @click="gomore"
        circle
      ></el-button>
    </div>
    <div class="vx-flex_item cmpContent">
      <el-carousel :autoplay="true" arrow="never" :interval="5000">
        <el-carousel-item v-for="(item, index) in items" :key="index">
          <a
            :href="'/resources/company/' + item.companyId"
            target="_blank"
            class="company_about vx-flex hoverMoveUp"
             data-ulog="查看企业"
          >
            <div class="cpImg">
              <rx-img
                :src="bindImg(item.logo)"
                defimg="/assets/images/def_logo.png"
                mode="contain"
                class="img"
                width="100px"
                height="100px"
              ></rx-img>
            </div>
            <div class="vx-flex_item cpAbout">
              <h3 class="h03  hover">{{ item.companyName }}</h3>
              <!-- <div class="tag_link">联系方式14个</div> -->
              <div v-if="item.entLabel">
                <span class="tag_a" v-for="(v,i) in tags(item.entLabel)" :key="i">
                  {{ v }}
                </span>
              </div>
            </div>
          </a>
          <item-company-need :items="item.demandList"></item-company-need>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>
<script>
import View from "../../../components/view.vue";
import ItemCompanyNeed from "./itemCompanyNeed";
export default {
  name: "item-company",
  components: { ItemCompanyNeed },
  props: {
    type: {
      type: String,
      default: "rgzn",
    },
    info: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      items: [],
      i: 0,
      comp: {
        rgzn: {
          img: require("../assets/sc25.png"),
          title: "销售需求",
        },
        g5: {
          img: require("../assets/sc26.png"),
          title: "采购需求",
        },
        swyy: {
          img: require("../assets/sc27.png"),
          title: "融资需求",
        },
        qc: {
          img: require("../assets/sc28.png"),
          title: "融资需求",
        },
      },
      pi: 0,
      pSize: 2,
    };
  },
  watch: {
    info: {
      handler(val) {
        this.items = val.enterpriseList;
      },
      immediate: true,
    },
  },
  created() {},
  methods: {
    selectItem(val) {
      this.i = val;
    },
    gomore() {
      let o = this.info.chainName + "::" + this.info.chainId;
      window.open("/resources/?industry=" + A.encodeUrl(o));
    },
    bindImg(data) {
      return "https://jhjoss.rongxiangipo.cn/bigdata/daas/" + data + ".jpg";
    },
    tags(v){
      return v.split(',')
    }
  },
};
</script>
<style lang="less" scoped>
.company {
  position: relative;
  width: calc(50% - 10px);
  height: 300px;
  box-shadow: 0px 6px 36px 0px rgba(0, 0, 0, 0.08);
  border-radius: 0 0 0px 10px;
  overflow: hidden;
  margin-bottom: 30px;
  .planeBar {
    position: relative;
    width: 54px;
    img {
      width: 100%;
      line-height: 0;
    }
  }
  .cmpContent {
    width: 100%;
    height: 300px;
    background-image: url("../assets/sc31.png");
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 100%;
    /deep/.el-carousel__button {
      background: #dddddd !important;
      width: 5px !important;
      height: 5px !important;
    }
    /deep/.el-carousel__indicator.is-active button {
      background: #2659ed !important;
      width: 8px !important;
      height: 8px !important;
      transform: translateY(1px);
    }
  }
  .moreBtn {
    position: absolute;
    right: 20px;
    top: 30px;
    border-radius: 50% !important;
    padding: 0 2px;
    border-color: #eeeeee;
    background-color: transparent;
    color: #ffffff;
    width: 22px;
    height: 22px;
    line-height: 20px;
    padding: 0;
    &.el-button:focus,
    &.el-button:hover {
      color: #2659ed;
      border-color: #ffffff;
      background-color: #ffffff;
    }
  }
  .company_about {
    height: 175px;
    padding: 50px 23px 5px 23px;
    padding: 0 16px;
    .cpImg {
      padding: 50px 0 5px 23px;
      .img {
        width: 100px;
        height: 100px;
        background: #ffffff;
        border: 1px solid #edf1f8;
        box-shadow: 0px 10px 36px 0px rgba(0, 0, 0, 0.08);
        overflow: hidden;
      }
    }
    .cpAbout {
      padding: 46px 0 5px 23px;
      .h03{
        padding-bottom: 5px;
      }
    }
    .tag_link {
      display: inline-block;
      color: #ec6833;
      font-size: 14px;
      height: 23px;
      background: #fdf3e4;
      padding: 0 10px;
      line-height: 23px;
      margin: 10px 0 23px 0;
    }
    .tag_a {
      display: inline-block;
      color: #1d44a6;
      font-size: 12px;
      padding: 0 10px;
      line-height: 23px;
      height: 23px;
      background: #edf1f8;
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
  .companylist {
    height: 113px;
    // background: linear-gradient(0deg, #ffffff 0%, #f2f2f2 100%);
    padding: 15px 25px;
    text-align: center;
    .cpItem {
      width: 75px;
      height: 75px;
      background-color: #ffffff;
      background-image: url("../assets/timg.jpg");
      background-size: 100%;
      background-position: center;
      box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.14);
      margin: 0 11px;
      position: relative;
      transition: transform 0.6s;
      display: inline-block;
      top: 0;
      &.act {
        border: 2px solid #ff7a00;
        box-shadow: 0px 3px 10px 0px rgba(216, 130, 0, 0.2);
        transform: translateY(-5px);
        &::after {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          border: 5px solid transparent;
          border-bottom: 9px solid#ff7a00;
          left: calc(50% - 4px);
          top: -16px;
        }
      }
    }
    .xjBtn {
      display: inline-block;
      width: 98px;
      height: 30px;
      border: 1px solid #2659ed;
      text-align: center;
      line-height: 30px;
      color: #2659ed;
      font-weight: bold;
    }
  }
}
</style>