<template>
  <div class="searchbar" :style="{ width: width }">
    <div class="vx-flex" :style="{ width: width }">
      <div class="vx-flex_item input">
        <input
          type="text"
          :placeholder="placeholder"
          ref="input"
          :value="value"
          @keydown="keydown"
          @input="change"
        />
        <i class="el-icon-search micon"></i>
      </div>
      <div class="btn" @click="search" :data-ulog="btnTxt">{{ btnTxt }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "rx-search-bar",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    btnTxt: {
      type: String,
      default: "搜索",
    },
    placeholder: {
      type: String,
      default: "输入你想查找的内容",
    },
    value: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "300px",
    },
    select: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    search() {
      this.$emit("click-search", this.$refs.input.value);
    },
    change(e) {
      this.$emit("change", e.currentTarget.value);
    },
    keydown(e) {
      if (e.keyCode == 13) {
        this.$emit("click-search", this.$refs.input.value);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.searchbar {
  display: inline-block;
  position: relative;
  vertical-align:middle;
  .btn {
    background-color: #2659ed;
    width: 80px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
    color: #ffffff;
    user-select: none;
    &:hover {
      background-color: #1d3f98;
    }
  }
  .input {
    flex: 1;
    border: 1px solid #edf1f8;
    border-right: none;
    user-select: none;
    background: linear-gradient(
      90deg,
      rgba(218, 225, 241, 0.5),
      rgba(239, 242, 249, 0.5)
    );
    position: relative;
    .micon {
      color: #999999;
      position: absolute;
      left: 10px;
      top: 50%;
      font-size: 16px;
      transform: translateY(-50%);
    }
    input {
      margin: 0;
      border: none;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      outline: none;
      padding-left: 40px;
      background-color: transparent;
      &::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
      }
    }
  }
}
</style>