<template>
  <div class="planes">
    <div class="title">
      <span class="arrow"><i class="el-icon-arrow-down"></i></span>
      免费发布{{ tab == "sale" ? "销售" : "采购" }}需求
    </div>
    <div class="putinput vx-flex">
      <div>
        <input v-model="pcname" :placeholder="
            tab == 'sale' ? '请输入您销售的产品名称' : '请输入您采购的产品名称'
          " />
      </div>
      <div class="putBtn" @click="publish" :data-ulog="(tab == 'sale' ? 'banner-发布销售需求' : 'banner-发布采购需求')">立即发布</div>
    </div>
    <div class="qhref vx-flex">
      <a href="/resources" target="_blank" data-ulog="banner-找客户">
        <img src="../assets/sc44.png" />找客户</a>
      <a data-ulog="banner-找需求"
        :href="tab == 'sale' ? '/resources/market?filter=%257B%2522demandType%2522%253A%25221%2522%257D' : '/resources/market?filter=%257B%2522demandType%2522%253A%25222%2522%257D'"
        target="_blank">
        <img src="../assets/sc45.png" />找需求
      </a>
      <a href="/ucenter/uenterprise" target="_blank" data-ulog="banner-我的企业">
        <img src="../assets/sc46.png" />我的企业
      </a>
      <a href="/ucenter/uneed" target="_blank" data-ulog="banner-管理需求">
        <img src="../assets/sc47.png" />管理需求
      </a>
    </div>
  </div>
</template>
<script>
  export default {
    name: "login-con-in",
    props: {
      tab: {
        type: String,
        default: "sale",
      },
    },
    watch: {
      tab() {
        this.pcname = "";
      },
    },
    data() {
      return {
        pcname: "",
      };
    },
    methods: {
      publish() {
        publish(this.tab, this.pcname);
      },
    },
  };
</script>
<style lang="less" scoped>
  .planes {
    height: 203px;
    background-color: #fafcff;
    padding: 2px 20px;

    .title {
      font-size: 16px;
      color: #2659ed;
      vertical-align: middle;
      margin: 12px 0;
      text-align: left;

      span {
        width: 16px;
        height: 16px;
        border: 1px #2659ed solid;
        border-radius: 50%;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        display: inline-block;
        margin-right: 8px;
        vertical-align: middle;
      }
    }

    .putinput {
      height: 40px;
      background: linear-gradient(-90deg,
          rgba(218, 225, 241, 0.5),
          rgba(239, 242, 249, 0.5));
      border: 1px solid #2659ed;

      input {
        border: none;
        background: transparent;
        padding: 10px;
        outline: none;
        width: 100%;
        line-height: 20px;
      }

      .putBtn {
        width: 80px;
        background: #2659ed;
        line-height: 40px;
        text-align: center;
        color: #ffffff;
        cursor: pointer;

        &:hover {
          background-color: rgb(74, 112, 228);
        }
      }
    }

    .qhref {
      padding: 22px 0;

      a {
        flex: 1;
        text-align: center;
        display: inline-block;
        color: #000000;

        &:hover {
          color: #2659ed;
        }

        img {
          width: 36px;
          display: block;
          margin: auto;
          margin-bottom: 10px;
        }

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
</style>