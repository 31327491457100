<template>
  <plane-home h2="圈子问答" h4="信息观点碰撞 人脉深度连接" cheight="450px">
    <rx-view class="homeQa" :loading="loading">
      <h3 class="tit hover" v-if="firstAsk.askTitle" @click="goPage(firstAsk)"  data-ulog="查看问答">{{ firstAsk.askTitle }}</h3>
      <div class="vx-flex indexItem"  v-if="firstAsk.answerList" @click="goPage(firstAsk)">
        <div class="iimg" v-if="firstAsk.answerList[0].answerImg">
          <rx-img
            height="113px"
            width="150px"
            :src="firstAsk.answerList[0].answerImg"
          ></rx-img>
        </div>
        <div class="vx-flex_item">
          <div class="uname">
            <rx-avatar
              height="23px"
              width="23px"
              :src="firstAsk.answerList[0].memberLogo"
            ></rx-avatar>
            {{ firstAsk.answerList[0].memberName }}
          </div>
          <div class="icont" v-html="firstAsk.answerList[0].answerContent">          </div>
        </div>
      </div>
      <div class="vx-flex iitemtag">
        <div class="vx-flex_item">
          <span class="tag">{{ firstAsk.groupName }}</span>
        </div>
        <div>
          <a class="hover22" href="javascript:void(0)"  @click="goPage(firstAsk)" data-ulog="查看问答"> 全部{{ firstAsk.answerNum }}个回答 </a>
        </div>
      </div>
      <div>
        <div class="vx-flex qitem hover" v-for="v in items" :key="v.askId"  @click="goPage(v)" data-ulog="查看问答">
          <div class="vx-flex_item">
            <i class="el-icon-question" /> {{v.askTitle}}
          </div>
          <div><span>全部{{v.answerNum}}个回答</span></div>
        </div>
      </div>
    </rx-view>
  </plane-home>
</template>
<script>
import mixinBase from "@src/components/mixins/mixinBase";
export default {
  name: "home-qa",
  mixins: [mixinBase],
  data() {
    return {
      firstAsk: {},
      items: [],
    };
  },
  computed: {
    loading() {
      return this.isLoading("getCircleAsk");
    },
  },
  created() {
    A.updata.getCircleAsk().then((res) => {
      if (res.data.records) {
        this.firstAsk = res.data.records[0];
        this.items = res.data.records.slice(1, 5);
      }
    });
  },
  mounted() {},
  methods: {
    goPage(val) {
      window.open(
        "/exchange/circleWendaDet?askId=" +
          val.askId +
          "&groupId=" +
          val.groupId,
        "_blank"
      );
    },
  },
};
</script>
<style lang="less" scoped>
.homeQa {
  padding: 36px 25px;
  color: #666666;
  .tit{
    cursor: pointer;
  }
  .indexItem {
    padding: 26px 0;
    padding-bottom: 12px;
    line-height: 1.8;
    cursor: pointer;
    .iimg {
      padding-right: 23px;
    }
    .icont {
      height: 63px;
      line-height: 21px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
  .uname {
    padding-bottom: 20px;
    vertical-align: middle;
    font-size: 16px;
    color: #000;
  }
  .iitemtag {
    padding-bottom: 30px;
    .tag {
      display: inline-block;
      padding: 0 5px;
      line-height: 23px;
      background: #edf1f8;
      color: #1d44a6;
      font-size: 14px;
    }
    .hover22{
      color: #1d44a6;
    }
  }
  .qitem {
    line-height: 1;
    padding: 10px 0;
    font-size: 16px;
    color: #000;
    cursor: pointer;
    i {
      color: #ff7a00;
      background: -webkit-linear-gradient(-90deg, #f3ae4b, #ff7a00);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 16px;
      margin-right: 5px;
    }
    span {
      color: #1d44a6;
      font-size: 14px;
    }
    .vx-flex_item {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
}
</style>