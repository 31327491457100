<template>
  <div class="homeLogin">
    <HomeLoginUser></HomeLoginUser>
    <HomeLoginCon @click-btn="publish"></HomeLoginCon>
  </div>
</template>
<script>
import { mapState } from "vuex";
import mixinBase from "@src/components/mixins/mixinBase";
import HomeLoginUser from "./homeLoginUser";
import HomeLoginCon from "./homeLoginCon";
export default {
  name: "home-login",
  components: { HomeLoginUser, HomeLoginCon },
  mixins: [mixinBase],
  computed: {
    ...mapState("user", {
      info: (state) => state.info,
      token: (state) => state.token,
    }),
  },
  data() {
    return {
      tab: "sale",
    };
  },
  created() {},
  mounted() {},
  methods: {
    publish(val) {
      this.$emit("click-publish", val);
    },
  },
};
</script>
<style lang="less" scoped>
.homeLogin {
  width: 300px;
  background: #ffffff;
  z-index: 100;
  text-align: center;
}
</style>