<template>
  <el-carousel
    height="100px"
    :interval="2000"
    direction="vertical"
    indicator-position="none"
    :autoplay="true"
  >
    <el-carousel-item v-for="item in items" :key="item.demandId">
      <div class="needitem vx-flex">
        <div class="vx-flex_item">
          <a :href="'/resources/purchase?id=' + item.demandId" target="_blank">
            <h3 class="hover">{{ item.demandTitle }}</h3></a
          >
          <span class="ntag">采购需求</span>
        </div>
        <div>
          <a
            class="xjBtn"
            :href="'/resources/purchase?id=' + item.demandId"
            target="_blank"
             data-ulog="立即询价"
            >立即询价</a
          >
        </div>
      </div>
    </el-carousel-item>
  </el-carousel>
</template>
<script>
export default {
  name: "item-company-need",
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    selectItem(val) {
      this.i = val;
    },
  },
};
</script>
<style lang="less" scoped>
.needitem {
  height: 100px;
  position: relative;
  padding: 28px;
  h3 {
    display: inline-block;
  }
  .ntag {
    display: inline-block;
    width: 76px;
    height: 21px;
    text-align: center;
    font-size: 12px;
    color: #ffffff;
    line-height: 21px;
    background-image: url("../assets/sc22.png");
    background-size: 100%;
    background-repeat: no-repeat;
    padding-left: 5px;
  }
  .xjBtn {
    display: inline-block;
    width: 98px;
    height: 30px;
    border: 1px solid #2659ed;
    text-align: center;
    line-height: 30px;
    color: #2659ed;
    font-weight: bold;
    &:hover {
      background-color: #e8edfa;
    }
  }
}
</style>