<template>
  <div class="planeHome">
    <div class="title">
      {{ h2 }}
      <span>{{ h4 }}</span>
    </div>
    <div class="planeContent" :style="{ height: cheight }">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "plane-home",
  props: {
    h2: {
      type: String,
      default: "",
    },
    h4: {
      type: String,
      default: "",
    },
    cheight: {
      type: String,
      default: "100px",
    },
  },
};
</script>
<style lang="less" scoped>
.planeHome {
  .title {
    color: #000000;
    font-size: 28px;
    line-height: 1;
    padding: 24px;
    vertical-align: middle;
    font-weight: bold;
    padding-left: 22px;
    span {
      color: #666666;
      font-size: 16px;
      line-height: 1;
      padding: 0 20px;
      font-weight: normal;
    }
  }
  .planeContent {
    background: #ffffff;
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
  }
}
</style>