<template>
  <rx-view>
    <template v-if="token && expertInfo.expertId">
      <div class="qhref">
        <a href="/zone/policyList" target="_blank" data-ulog="banner-查政策">
          <img src="../assets/sc40.png" />查政策</a
        >
        <a href="/exchange/circleWenda" target="_blank" data-ulog="banner-去交流">
          <img src="../assets/sc41.png" />去交流
        </a>
        <a href="/exchange/researchReport" target="_blank" data-ulog="banner-读研报">
          <img src="../assets/sc42.png" />读研报
        </a>
        <a href="/exchange/news" target="_blank" data-ulog="banner-看资讯">
          <img src="../assets/sc43.png" />看资讯
        </a>
      </div>
    </template>
    <template v-else>
      <div class="vx-flex tabs">
        <div
          class="vx-flex_item mu"
          :class="{ act: tab == 'sale' }"
          @click="tab = 'sale'"
           data-ulog="banner-找销售渠道"
        >
          <h3>
            我有产品
            <i class="el-icon-arrow-down"></i>
          </h3>
          <div>找销售渠道</div>
        </div>
        <div
          class="vx-flex_item mu"
          :class="{ act2: tab == 'buy' }"
          @click="tab = 'buy'"
           data-ulog="banner-找供货来源"
        >
          <h3>
            我要采购
            <i class="el-icon-arrow-down"></i>
          </h3>
          <div>找供货来源</div>
        </div>
      </div>
      <LoginConIn
        :tab="tab"
        @click-btn="$emit('click-btn', tab)"
        v-if="token"
      ></LoginConIn>
      <LoginConOut
        :tab="tab"
        @click-btn="$emit('click-btn', tab)"
        v-else
      ></LoginConOut>
    </template>
  </rx-view>
</template>
<script>
import { mapState } from "vuex";
import LoginConOut from "../components/loginConOut";
import LoginConIn from "../components/loginConIn";
export default {
  name: "home-login-con",
  components: { LoginConOut, LoginConIn },
  computed: {
    ...mapState("user", {
      info: (state) => state.info,
      token: (state) => state.token,
      expertInfo: (state) => state.expertInfo,
    }),
  },
  data() {
    return {
      tab: "sale",
    };
  },
};
</script>
<style lang="less" scoped>
.qhref {
  background: #f5f7fb;
  padding: 32px 16px;
  a {
    margin: 0 12px;
    text-align: center;
    display: inline-block;
    max-width: 60px;
    color: #000000;
    img {
      width: 36px;
      display: block;
      margin: auto;
      margin-bottom: 10px;
    }
  }
}
.tabs {
  .mu {
    text-align: left;
    padding-left: 26px;
    padding-top: 8px;
    color: #666666;
    font-size: 14px;
    line-height: 1.3;
    height: 62px;
    border-bottom: 1px #ccd5ec solid;
    cursor: pointer;
    i {
      font-size: 12px;
    }
    h3 {
      color: #000000;
      font-size: 18px;
    }
    &.act {
      cursor: default;
      background-color: #fafcff;
      padding-top: 14px;
      border-bottom: none;
      border-top: 1px #ccd5ec solid;
      border-right: 1px #ccd5ec solid;
      border-radius: 0 5px 0 0;
      h3 {
        color: #2659ed;
      }
    }
    &.act2 {
      cursor: default;
      background-color: #fafcff;
      padding-top: 14px;
      border-bottom: none;
      border-top: 1px #ccd5ec solid;
      border-left: 1px #ccd5ec solid;
      border-radius: 5px 0 0 0;
      h3 {
        color: #2659ed;
      }
    }
  }
}
.planes {
  height: 203px;
  background-color: #fafcff;
  padding: 2px 26px;
  .items {
    margin: 10px 0 30px 0;
  }
  .item {
    text-align: left;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    span {
      float: right;
      color: #f3a600;
    }
  }
}
</style>