<template>
  <div
    class="rx-page"
    :class="type"
    :style="{height:(type=='root'||type=='full')?'calc(100% - '+(0)+'px)':''}"
  >
    <pageHeader
      :class="border"
      :bn="bn"
      :title="title"
      :ibtn="topIbtn"
      :syl="topStyleData"
      :fixed="fixedTop"
      :hide="topHide"
      :type="type"
      ref="header"
    >
      <slot name="topRight"></slot>
    </pageHeader>
    <wapView
      ref="wrapper"
      :pulldown="pulldown"
      :pull="pull"
      :nomore="nomore"
      :items="items"
      :hideBar="hideBar"
      @scroll-bottom="$emit('scroll-bottom')"
      @pull-down="$emit('scroll-pulldown')"
      @scroll="__onPageScrollTop"
      :style="{height:'calc(100% - '+44+'px)'}"
      :wrapperClass="wrapperClass"
    >
    <!-- <div class="main_wrapper"> -->
      <slot></slot>
      <page-footer v-if="hideBottom" @goTop="goTop"></page-footer>
    <!-- </div> -->
    </wapView>
    <slot name="overlap"></slot>
  </div>
</template>

<script>
/**
 * type类型（none,child,root）
 * none：头部条和底部导航都没有
 * child：只有头部条，没有底部导航，一般用于内部子页面
 * root：只有底部导航，没有头部条，一般用于主页
 */
import { mapState } from "vuex";
import pageHeader from "./pageHeader";

export default {
  name: "page-wap",
  computed: {
  },
  components: {
    pageHeader
  },
  props: {
    nomore: Boolean,
    items: Array,
    title: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "child",
    },
    fixedTop: {
      type: Boolean,
      default: false,
    },
    fixedTopSyl: {
      type: String,
      default: "",
    },
    topIbtn: {
      type: String,
      default: "back",
    },
    topSyl: {
      type: Object,
      default: function () {
        return {};
      },
    },
    border: {
      type: String,
      default: "",
    },
    bn: {
      type: Number,
      default: -1,
    },
    pulldown: {
      type: Boolean,
      default: false,
    },
    pull: {
      type: Object,
    },
    topHide: {
      type: Array,
      default() {
        return [];
      },
    },
    hideBar: {
      type: Boolean,
      default: true,
    },
    wrapperClass: {
      type: String,
      default: "",
    },
    hideBottom: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      cutHeight: 44,
      reachBottom: false,
      showtop: false,
      iswx: false,
      // top样式
      topStyleData: {},
      invert: 0,
    };
  },
  watch: {
    topSyl(a) {
      this.topStyleData = a;
    },
    fixedTopSyl: {
      handler(a) {
        if (this.fixedTop) {
          switch (a) {
            case "default":
              this.topStyleData = {
                backgroundColor: "transparent",
                color: "#ffffff",
              };
              break;
            default:
              this.topStyleData = {
                backgroundColor: "transparent",
                color: "#ffffff",
              };
              break;
          }
        }
      },
      immediate: true,
    },
  },
  created() {
    this.navbarChange(this.type);
    this.iswx = A.os.iswx;
  },
  mounted() {
  },
  activated() {
    this.navbarChange(this.type);
    // this.scrollY(this.scrolltop);
  },
  methods: {
    navbarChange(type) {
      switch (type) {
        case "full":
          if (this.fixedTop) {
            this.cutHeight = 0;
          } else {
            // this.cutHeight = winSize.topHeight + this.DVCTop;
          }
          this.showtop = true;
          // this.$store.commit("navbar/showbar", true);
          break;
        case "root":
          this.cutHeight = 0;
          this.showtop = false;
          //this.$store.commit("navbar/showbar", true);
          break;
        case "child":
          if (this.fixedTop) {
            this.cutHeight = 0;
          } else {
            // this.cutHeight = winSize.topHeight + this.DVCTop;
          }
          this.showtop = true;
          //this.$store.commit("navbar/showbar", false);
          break;
        case "none":
          this.cutHeight = 0;
          this.showtop = false;
          //this.$store.commit("navbar/showbar", false);
          break;
        default:
          this.cutHeight = 0;
          this.showtop = false;
          //this.$store.commit("navbar/showbar", true);
      }
      if (A.os.iswx) {
        return (this.cutHeight = 0);
      }
    },
    __onPageScrollTop(e) {
      if (this.fixedTop) {
        if (e.target.scrollTop > 0 && e.target.scrollTop <= 50) {
          this.topStyleData = {
            backgroundColor:
              "rgba(255,255,255," + e.target.scrollTop / 50 + ")",
            color: "#333333",
          };
          this.invert = e.target.scrollTop / 50;
        } else if (e.target.scrollTop == 0) {
          this.topStyleData = {
            backgroundColor: "rgba(255,255,255,0)",
            color: "#ffffff",
          };
          this.invert = 0;
        } else if (e.target.scrollTop > 50) {
          this.topStyleData = {
            backgroundColor: "rgba(255,255,255,1)",
            color: "#333333",
          };
          this.invert = 1;
        }
      }
      this.$emit("scroll", e, this.invert);
    },
    getWrapper() {
      return this.$refs.wrapper;
    },
    scrollY(val) {
      this.$refs.wrapper.scrollY(val);
    },
    back() {
      this.$router.back();
    },
    goTop() {
      this.$refs.wrapper.scrollY(0);
    },
  },
};
</script>
<style lang="less">
.rx_wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  // overflow: hidden;
}
.main_wrapper {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  // overflow-x: hidden;
  // overflow-y: auto;
  overscroll-behavior-y: none;
}
.rx-page {
  position: absolute;
  overflow-x: hidden;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  &.full {
    height: calc(100% - 50px);
  }
  &.root {
    height: calc(100% - 50px);
  }
  &.child {
    height: 100%;
  }
  &.none {
    height: 100%;
  }
}
</style>
