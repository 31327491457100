<template>
  <div class="homeMenu">
    <div class="vx-flex mulist">
      <div class="menu act" @click="clickItem()">
        <span>首页</span>
      </div>
      <div class="vx-flex_item menus">
        <rx-menu-dropdown class="menu bold">
          <a href="/resources"  data-ulog="导航-产业资源库">
            产业资源库
            <i class="el-icon-arrow-down"></i>
          </a>
          <template #dropdown>
            <div class="navmenu">
              <a href="/resources" data-ulog="导航-企业库">企业库</a>
              <a href="/resources/market" data-ulog="导航-需求市场">需求市场</a>
            </div>
          </template>
        </rx-menu-dropdown>
      </div>
      <div class="vx-flex_item menus">
        <a href="/abutment" class="alink" data-ulog="导航-产业对接"> 产业对接 </a>
      </div>
      <div class="vx-flex_item menus">
        <rx-menu-dropdown class="menu bold">
          <a href="/zone/index" data-ulog="导航-产业扶持">
            产业扶持
            <i class="el-icon-arrow-down"></i>
          </a>
          <template #dropdown>
            <div class="navmenu">
              <a href="/zone/index" data-ulog="导航-产业园区">产业园区</a>
              <a href="/zone/policy" data-ulog="导航-政策智配">政策智配</a>
              <a href="/zone/fhcity" data-ulog="导航-奉化城市展厅">奉化城市展厅</a>
            </div>
          </template>
        </rx-menu-dropdown>
      </div>
      <div class="line">
        <span></span>
      </div>
      <div class="vx-flex_item menus">
        <rx-menu-dropdown class="menu">
          <a href="/bigdata/0100000000" data-ulog="导航-产业大数据">
            产业大数据
            <i class="el-icon-arrow-down"></i>
          </a>
          <template #dropdown>
            <div class="navmenu">
              <a href="/bigdata/0100000000" data-ulog="导航-产业全景图">产业全景图</a>
              <a href="/bigdata/0100000000?tab=1" data-ulog="导航-产业地图">产业地图</a>
            </div>
          </template>
        </rx-menu-dropdown>
      </div>
      <div class="vx-flex_item menus">
        <rx-menu-dropdown class="menu">
          <a href="/exchange" data-ulog="导航-产业交流">
            产业交流
            <i class="el-icon-arrow-down"></i>
          </a>
          <template #dropdown>
            <div class="navmenu">
              <a href="/exchange/circleWenda" data-ulog="导航-圈子问答">圈子问答</a>
              <a href="/exchange/professionConsult" data-ulog="导航-专业咨询">专业咨询</a>
              <a href="/exchange/researchReport" data-ulog="导航-行业研报">行业研报</a>
              <a href="/exchange/news" data-ulog="导航-产业资讯">产业资讯</a>
            </div>
          </template>
        </rx-menu-dropdown>
      </div>
      <div class="ad">
        <a href="/server" class="homeTopAd" data-ulog="产品服务">
          <img src="../assets/homead01.gif" />
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "home-menu",
  props: {
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      idx: 0,
    };
  },
  created() {},
  mounted() {},
  methods: {
    clickItem(item, i) {},
  },
};
</script>
<style lang="less" scoped>
.homeMenu {
  position: relative;
  text-align: center;
  color: #111111;
  font-size: 15px;
  height: 80px;
  padding: 0;
  box-sizing: border-box;
  padding-top: 30px;
  .mulist {
    width: 1200px;
    margin: auto;
  }
  .menus {
    box-sizing: border-box;
    .alink {
      font-size: 18px;
      display: block;
      line-height: 50px;
      font-weight: bold;
      color: #2659ed;
      &:hover {
        color: #4977ff;
      }
    }
  }
  .menu {
    min-width: 95px;
    line-height: 48px;
    display: inline-block;
    position: relative;
    color: #2659ed;
    font-size: 18px;
    z-index: 100;
    border:2px #ffffff solid;
    border-bottom: none;
    cursor: default;
    a {
      color: #2659ed;
      &:hover {
        color: #4977ff;
      }
    }
    &.hover {
      background: #ffffff;
      border: 2px #2659ed solid;
      z-index: 11;
      .el-icon-arrow-down{
        transform: rotate(180deg);
      }
    }
    &.bold {
      a {
        font-weight: bold;
      }
    }
    &.act {
      height: 50px;
      line-height: 52px;
      color: #ffffff;
      background-color: #2659ed;
      border:none;
      border-bottom: 2px #2659ed solid;
      border-radius: 3px 3px 0px 0px;
      overflow: hidden;
      margin-right: 10px;
    }
    .el-icon-arrow-down {
      font-size: 12px;
      color: #cccccc;
      margin-left: 6px;
    }
    .navmenu {
      font-size: 14px;
      font-weight: normal;
      background-color: #ffffff;
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
      // border: 2px #2659ed solid;
      border-top: none;
      width: 140px;
      text-align: left;
      z-index: 100;
      a {
        display: block;
        padding: 0;
        padding-left: 18px;
        line-height: 3;
        color: #111111;
        border-bottom: 1px #f1f1f1 solid;
        font-size: 16px;
        font-weight: normal;
        &:hover {
          background-color: #f5f5f5;
          color: #2659ed;
        }
      }
    }
  }
  .line {
    span {
      display: inline-block;
      background-color: #d5d5d5;
      width: 1px;
      height: 24px;
      margin-top: 12px;
    }
  }
  .ad {
    width: 320px;
    position: relative;
    .homeTopAd {
      position: absolute;
      height: 78px;
      top: -30px;
      right: 0;
      overflow: hidden;
      img{
        height: 80px;
      }
    }
  }
}
</style>