<template>
  <div class="rx-page" :class="type">
    <slot></slot>
  </div>
</template>

<script>
/**
 * type类型（none,child,root）
 * none：头部条和底部导航都没有
 * child：只有头部条，没有底部导航，一般用于内部子页面
 * root：只有底部导航，没有头部条，一般用于主页
 */

export default {
  name: "rx-page",
  computed: {},
  components: {},
  props: {
    title: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "child",
    },
  },
  data() {
    return {};
  },
  watch: {},
  created() {},
  mounted() {},
  activated() {},
  methods: {
    back() {
      this.$router.back();
    },
  },
};
</script>
<style lang="less">
.rx-page {
  position: relative;
}
</style>
