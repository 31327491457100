<template>
  <div id="Nothing">
    <h3>Nothing</h3>
  </div>
</template>

<script>
import Page from "vwx/page";

export default Page({
  name: "nothing",
  data: {},
  computed: {},
  mounted() {},
  methods: {},
  components: {},
});
</script>
<style lang="less" scoped>
#Nothing {
  max-width: 1200px;
  margin: auto;
}
</style>
