<template>
  <transition :name="transitionName">
    <keep-alive :include="keepPages">
      <router-view class="jhj-view"></router-view>
    </keep-alive>
  </transition>
</template>

<script>

var keepPages = [];

export default {
  name: "rx-wrapper",
  data() {
    return {
      keepPages: [],
      transitionName: "page-right",
    };
  },
  created() {
    let childrens = this.$parent.$router.options.routes;
    keepPages = childrens
      .filter((child) => {
        if (child.component.name == "page") return false; //默认未命名的不缓存,有的页面没有写name又配置了keepalive导致其他所有没有name的都被缓存
        return child.meta
          ? child.meta.keepAlive
            ? child.meta.keepAlive
            : false
          : false;
      })
      .map((item) => item.component.name);
      this.keepPages = keepPages.concat([]);
      A.on("logout", () => {
        this.keepPages = [];
        this.installKeepPages();
      });
      A.on("login", () => {
        this.keepPages = [];
        this.installKeepPages();
      });
  },
  watch: {
    $route(to, from) {
      if (this.$router.isBack) {
        this.transitionName = "page-right";
      } else {
        this.transitionName = "page-left";
      }
      this.$router.isBack = false;
    },
  },
  methods: {
    delKeepAlive(name) {
      let index = this.keepPages.findIndex((n) => n == name);
      if (index > 0) {
        this.keepPages.splice(index, 1);
        this.installKeepPages();
      }
    },
    installKeepPages() {
      setTimeout(() => {
        this.keepPages = keepPages.concat([]);
      }, 300);
    },
  },
};
</script>

<style>
.jhj-view {
  transition: all 0.4s cubic-bezier(0.55, 0, 0.1, 1);
  /* transition: all .4s cubic-bezier(0.645, 0.045, 0.355, 1); */
}
.page-left-enter,
.page-right-leave-active {
  transform: translate(100%, 0);
}

.page-right-enter,
.page-left-leave-active {
  transform: translate(-100%, 0);
}
</style>
