import {
    mapState
} from "vuex";

const getStrToObj = function (_str, zz) {
    let zpStr = _str.match(zz);
    zpStr = zpStr ? zpStr[1] : null;
    let zpObj = {};
    if (zpStr) {
        let zpArr = zpStr.split('&');
        for (var i = 0; i < zpArr.length; i++) {
            var subArr = zpArr[i].split('=');
            zpObj[subArr[0]] = subArr[1];
        }
    }
    return zpObj;
}

export default {
    data() {
        return {
            __status: 0,
            // 是否微信
            ISWX: false,
            // 当前系统
            COS: 'other',
            // 是否准备好数据
            READYDATA: false,
            // 页面窗口标题
            PageTitle: "",
        }
    },
    computed: {
        ...mapState("global", {
            apiLoading: (state) => state.apiLoading,
            apiLoads: (state) => state.apiLoads,
        }),
    },
    methods: {
        isLoading(apiname) {
            if (typeof apiname == 'string') {
                let lds = apiname.split(',');
                if (lds.length == 1) {
                    return this.apiLoads.find((n) => n == apiname) ? true : false;
                } else {
                    let loading = false;
                    for (let k in lds) {
                        let bo = this.apiLoads.find((n) => n == lds[k]) ? true : false;
                        if (bo) {
                            loading = bo;
                            break;
                        }
                    }
                    return loading;
                }
            }
        },
        // 分享页面
        onShare(_options) {
            console.log('准备分享：', this.ShareContent)
            let title = this.ShareTitle.length > 30 ? this.ShareTitle.slice(0, 30) + '...' : this.ShareTitle;
            let content = this.ShareContent.replace(/<\/?.+?\/?>/g, "").replace(/[ ]/g, '');
            content = content.length > 100 ? content.slice(0, 100) : content;
            let options = {
                title,
                content,
                thumbs: this.ShareImage
            }
            if (this.ShareHref != "" && this.ShareHref != undefined && this.ShareHref != null) options.href = this.ShareHref;
            Object.assign(options, _options);
            if (this.ISWX) {
                return options;
            } else {
                console.log('准备分享内容处理：', options.content)
            }
        },
        sharePageFinish() {},
        goPage(_name, _obj) {
            if (typeof A.router == undefined) return;
            if (typeof _name == 'string') {
                if (/^https?:\/\//.test(_name)) return window.open(_name, "_blank");
                let replace = _name.match(/:replace(\S*)/);
                let name = replace ? replace[1] : _name;
                let _route = {
                    name
                };
                if (/^\//.test(name)) {
                    _route = {
                        path: name
                    }
                }
                if (typeof _obj == 'object') {
                    Object.assign(_route, _obj);
                } else if (typeof _obj == 'string') {
                    Object.assign(_route, {
                        params: getStrToObj(_obj, /p\/(.+?)\/p/),
                        query: getStrToObj(_obj, /q\/(.+?)\/q/)
                    });
                }
                if (replace) {
                    A.router.replace(_route)
                } else {
                    A.router.push(_route)
                }
            } else {
                if (_obj) {
                    A.router.replace(_name)
                } else {
                    A.router.push(_name)
                }
            }
        },
        error(err) {},
        encode(val) {
            return encodeURIComponent(val)
        },
        Toast(value, type, time) {
            if (typeof value == 'string') {
                this.$createToast({
                    txt: value,
                    type: type || 'txt',
                    time: time || 2000
                }).show();
            } else {
                this.$createToast(value).show();
            }
        },
    }
}