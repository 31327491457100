<template>
  <div>
    <el-dialog
      class="publish"
      title="提示"
      :visible.sync="isopen"
      :close-on-click-modal="false"
    >
      <div slot="title" class="dialog-title vx-flex">
        <div
          class="vx-flex_item"
          :class="{ act: tab == 'sale' }"
          @click="tab = 'sale'"
        >
          销售需求
        </div>
        <div
          class="vx-flex_item"
          :class="{ act: tab == 'buy' }"
          @click="tab = 'buy'"
        >
          采购需求
        </div>
      </div>
      <div>
        <HomePublishSale
          :levelList="levelList"
          @click-cancel="isopen = false"
          :title="title"
          v-if="tab == 'sale'"
        ></HomePublishSale>
        <HomePublishBuy
          :levelList="levelList"
          @click-cancel="isopen = false"
          :title="title"
          v-else
        ></HomePublishBuy>
      </div>
    </el-dialog>
    <div id="initNECaptcha" v-if="inityd"></div>
  </div>
</template>
<script>
import HomePublishSale from "./homePublishSale";
import HomePublishBuy from "./homePublishBuy";
export default {
  name: "demand-publish",
  components: { HomePublishSale, HomePublishBuy },
  props: {},
  data() {
    return {
      title: "",
      isopen: false,
      levelList: [],
      tab: 0,
      inityd: false,
    };
  },
  created() {
    A.updata.getLevel({ level: 3 }).then((res) => {
      if (res.success) {
        this.levelList = res.data;
      }
    });
  },
  mounted() {
    if (!document.querySelector("#initNECaptcha")) {
      this.inityd = true;
    }
  },
  methods: {
    open(val, title = "") {
      this.tab = val || 0;
      this.isopen = true;
      this.title = title;
    },
    onSubmit() {},
  },
};
</script>
<style lang="less" scoped>
.publish {
  /deep/.el-dialog {
    width: 810px;
  }

  /deep/.el-dialog__header {
    padding: 0;
  }

  /deep/.el-dialog__body {
    padding: 30px;
  }

  /deep/.el-input__inner {
    border-radius: 0;
  }

  /deep/.el-select {
    width: 100%;
  }
  /deep/.el-cascader {
    width: 100%;
  }
  /deep/.el-form-item__label {
    color: #333333;
    padding-right: 20px;
  }
  /deep/.el-dialog__headerbtn {
    font-size: 26px;
    right: -30px;
    top: 0;
    color: #ffffff;
    .el-dialog__close {
      color: #ffffff;
    }
    &:hover {
      .el-dialog__close {
        color: #ffff00;
      }
    }
  }
  .inpuvad {
    width: 200px;
    margin: 0 10px;
  }
  button {
    font-size: 16px;
    width: 100%;
  }
  .cancel {
    width: 188px;
    margin-right: 20px;
    color: #666666;
    .button {
      border: 1px solid #bbbbbb;
    }
  }
  .tip {
    color: #609a02;
  }
}
.dialog-title {
  height: 71px;
  background: #f5f8fc;
  font-size: 18px;
  color: #666666;
  font-weight: bold;
  text-align: center;
  line-height: 68px;
  cursor: pointer;
  .act {
    color: #2659ed;
    border-bottom: 2px solid #2659ed;
  }
}
</style>