export default [
    "message",
    "notify",
    "personal",
    "certification",
    "uenterprise",
    "modifyphone",
    "modifypassword",
    "otherauthentication",
    "uclient",
    "uneed",
    "ucenter"
]