<template>
  <router-view class="rx-p-view"></router-view>
</template>

<script>
var keepPages = [];

export default {
  name: "rx-p-view",
  data() {
    return {
      keepPages: [],
      transitionName: "page-right",
    };
  },
  created() {
    let childrens = this.$parent.$router.options.routes;
    keepPages = childrens
      .filter((child) => {
        if (child.component.name == "page") return false; //默认未命名的不缓存,有的页面没有写name又配置了keepalive导致其他所有没有name的都被缓存
        return child.meta
          ? child.meta.keepAlive
            ? child.meta.keepAlive
            : false
          : false;
      })
      .map((item) => item.component.name);
    this.keepPages = keepPages.concat([]);
    A.on("logout", () => {
      this.keepPages = [];
      this.installKeepPages();
    });
    A.on("login", () => {
      this.keepPages = [];
      this.installKeepPages();
    });
  },
  watch: {
    $route(to, from) {
      A.nav.isBack = this.$router.isBack;
      if (this.$router.isBack) {
        this.delKeepAlive(A.currentPage.PAGE_NAME);
      }
      this.$router.isBack = false;
    },
  },
  methods: {
    delKeepAlive(name) {
      let index = this.keepPages.findIndex((n) => n == name);
      if (index > 0) {
        this.keepPages.splice(index, 1);
        this.installKeepPages();
      }
    },
    clearKeepAlive() {
      A.assign({
        clearKeepAlive: () => {
          this.keepPages = [];
          this.installKeepPages();
        },
      });
    },
    installKeepPages() {
      setTimeout(() => {
        this.keepPages = keepPages.concat([]);
      }, 300);
    },
  },
};
</script>
<style scoped>
.rx-p-view{
  position: relative;
}
</style>
